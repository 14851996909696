.label {
  a {
    border-color: inherit;
    color: inherit;
  }

  &-success,
  &-warning,
  &-danger {
    display: inline-block;
    font-weight: $bold;
  }

  &-success {
    color: palette(success);
  }

  &-warning {
    color: palette(warning);
  }

  &-danger {
    color: palette(danger);
  }
}

input,
textarea {
  font-family: $font-family;
  font-size: 1em;
  max-width: 100%;

  &::placeholder {
    color: palette(text, light);
  }

  &,
  &:focus {
    outline: 0;
  }
}

// search
.search-form {
  position: relative;
}

.search-submit {
  @include poa(50%, 20px);

  color: palette(text, light);
  font-size: 17px;
  height: 20px;
  opacity: .5;
  transform: translateY(-50%);
}

.search-input {
  background-color: $white-color;
  border: 0;
  border-radius: 2em;
  padding: 10px 40px;
  width: 100%;

  @media (min-width: $md-width) {
    padding: 15px 40px;
  }

  &:focus {
    outline: 0;
  }
}

.form-group {
  font-size: 1.6rem;
  margin-bottom: $gutter / 2;
  position: relative;

  &-help {
    margin-bottom: $gutter / 2;

    .form-group {
      margin-bottom: 0;
    }

    .help {
      display: block;
      font-size: 1.4rem;
      padding-left: $gutter / 2;
      padding-top: .5rem;
    }
  }

  .form-control {
    border: 1px solid palette(border);
    border-radius: $border-radius;
    height: 40px;
    padding: ($gutter / 2);
    width: 100%;

    @media (min-width: $md-width) {
      height: 50px;
    }
  }

  &-text {
    .row > & {
      label {
        left: 3rem;
      }
    }

    label {
      @include poa(50%, 0, null, $gutter / 2);

      cursor: text;
      transform: translateY(-50%);
      transition: .2s;
      z-index: 10;
    }

    input,
    select {
      &:focus,
      &.fill {
        padding-bottom: 1.3rem;
        padding-top: 3rem;

        & + label {
          font-size: 1.4rem;
          transform: translateY(-100%);
        }
      }
    }

    select {
      &.fill {
        & ~ span {
          padding-top: 15px;

          &::after {
            margin-top: -15px;
          }
        }
      }
    }
  }

  &-textarea {
    label {
      top: 1rem;
      transform: none;
    }

    .form-control {
      font-size: 1em;
      min-height: 100px;
      padding: 2.5rem 1.5rem 0;

      &:focus,
      &.fill {
        & + label {
          font-size: 1.4rem;
          top: 1rem;
        }
      }
    }
  }

  &.password-valid {
    margin-top: 20px;
  }
}

.password {
  .icon-eye {
    @include poa(50%, 1rem);

    color: palette(text, light);
    cursor: pointer;
    font-size: 1em;
    transform: translateY(-50%);
    z-index: 11;

    &:hover,
    &.is-shown {
      color: palette(text);
    }
  }
}

.form-ok {
  .form-control {
    border-color: palette(success);
    color: palette(success);
  }
}

.form-error {
  .form-control {
    border-color: palette(danger);
    color: palette(danger);
  }
}

.custom-select {
  position: relative;

  > label {
    color: palette(text);
    display: block;
    margin-bottom: 7px;
    text-align: left;

    @media (min-width: $xs-width) {
      margin-bottom: 10px;
    }
  }

  > span {
    align-items: center;
    background-color: $white-color;
    border: 1px solid palette(border);
    color: palette(text);
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    line-height: 34px;
    min-height: 40px;
    overflow: hidden;
    padding: 0 0 0 1.5rem;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    z-index: 1;

    @media (min-width: $md-width) {
      min-height: 50px;
    }

    &::after {
      color: palette(text, light);
      content: icon(arrow-down);
      cursor: pointer;
      display: block;
      font-family: $icon-font;
      font-size: 10px;
      line-height: 1;
      margin-left: 1rem;
      margin-right: 1.5rem;
      opacity: 1;
      transition: .2s ease;
    }

    &.open {
      border-color: palette(border);

      &::after {
        opacity: 1;
        transform: rotate(180deg);
      }
    }
  }

  > ul {
    background: $white-color;
    border: 1px solid palette(border);
    border-radius: $border-radius;
    display: none;
    margin: -1px 0 0;
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
    position: absolute;
    z-index: 20;

    li {
      color: palette(text);
      cursor: pointer;
      padding: 12px 10px;

      input {
        height: 30px;
        width: 100%;
      }

      &:hover,
      &.selected {
        background-color: palette(background);
      }
    }

    &.open {
      display: block;
    }
  }

  &.disabled > span {
    cursor: not-allowed;
    opacity: .5;
  }

  &.disabled > label .label {
    cursor: not-allowed;
    opacity: .5;
  }

  & > select {
    display: none;
  }
}

.input-button {
  &-ctn {
    align-items: stretch;

    &.row {
      margin-left: -$gutter / 4;
      margin-right: -$gutter / 4;
    }
  }

  .row & {
    padding-left: $gutter / 4;
    padding-right: $gutter / 4;
  }

  .custom-checkbox,
  .custom-radio {
    padding: 0;

    &::before,
    &::after {
      content: none;
    }
  }

  .custom-radio,
  .custom-checkbox,
  label {
    height: 100%;
  }

  .checked,
  &:hover {
    label {
      background-color: palette(text);
      color: $white-color;
    }
  }

  label {
    background-color: $white-color;
    border: 1px solid palette(border);
    border-radius: $border-radius;
    color: palette(text);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
    text-align: center;
    transition: background-color .2s;
    width: 100%;
  }
}

.custom-checkbox {
  font-size: 0;
  position: relative;

  label,
  &::before {
    display: inline-block;
    vertical-align: top;
  }

  label {
    cursor: pointer;
    font-size: 1.6rem;
    max-width: calc(100% - 25px);
  }

  input {
    cursor: pointer;
    height: 15px;
    left: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 15px;
    z-index: 3;
  }

  &::before {
    border: 1px solid palette(border, dark);
    content: '';
    cursor: pointer;
    height: 15px;
    margin-right: 1rem;
    position: relative;
    transition: .2s ease;
    width: 15px;
    z-index: 1;
  }

  &::after {
    color: palette(text);
    content: icon(check);
    cursor: pointer;
    display: block;
    font-family: $icon-font;
    font-size: 7px;
    line-height: 1;
    opacity: 0;
    position: absolute;
    text-align: center;
    top: 4px;
    transition: .2s ease;
    width: 15px;
    z-index: 2;
  }

  &.checked {
    &::after {
      opacity: 1;
    }
  }
}

.custom-radio {
  clear: both;
  padding: 0 0 0 30px;
  position: relative;

  label {
    color: palette(text, dark);
    cursor: pointer;
    display: inline-block;
    text-transform: none;
    vertical-align: middle;

    small {
      display: block;
      font-size: 14px;
    }
  }

  input {
    cursor: pointer;
    height: 100%;
    left: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &::before {
    background-color: $white-color;
    border: 1px solid palette(border, dark);
    border-radius: 50%;
    content: '';
    cursor: pointer;
    display: block;
    height: 20px;
    left: 0;
    position: absolute;
    top: 0;
    width: 20px;
  }

  &::after {
    background-color: palette(text);
    border-radius: 50%;
    content: '';
    cursor: pointer;
    display: block;
    height: 10px;
    left: 5px;
    opacity: 0;
    position: absolute;
    top: 5px;
    transform: scale(0);
    transition: .2s ease;
    width: 10px;
  }

  &.checked {
    &::after {
      opacity: 1;
      transform: scale(1);
    }

    &::before {
      border-color: palette(text);
    }
  }

  &.inline {
    label {
      margin-bottom: 0;
      max-width: 100%;
    }

    &::before {
      color: palette(text, dark);
      cursor: pointer;
      display: inline-block;
      text-transform: none;
      vertical-align: middle;
    }
  }
}

// CUSTOM FILE (CONTACT PAGE)
.custom-file {
  border: .1rem solid palette(border);
  display: flex;
  justify-content: space-between;
  padding: 1.2rem 1.5rem;
  position: relative;

  .form-control {
    @include poa(auto, 0, 0, 0);

    cursor: pointer;
    height: 50px;
    opacity: 0;
    width: 100%;
    z-index: 1;
  }

  .filename {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .action {
    cursor: pointer;
    margin-left: 1rem;

    &::after {
      content: icon(attachment);
      font-family: $icon-font;
    }
  }
}

.radio-box {
  cursor: pointer;
  max-width: 100%;

  > label {
    display: block;
  }

  > input {
    cursor: pointer;
    height: 49px;
    opacity: 0;
    position: absolute;
    width: 100%;
  }

  .filename {
    border: 0;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    margin-right: 10px;
    max-width: 60%;
    overflow: hidden;
    padding: 0 15px;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;
  }

  .action {
    display: inline-block;
    text-transform: uppercase;
    vertical-align: middle;

    &::after {
      display: inline-block;
      font-family: $icon-font;
      margin-left: 6px;
      position: relative;
      top: 3px;
    }
  }
}
