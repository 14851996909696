.menu-account {
  .account-title {
    display: none;

    @media (min-width: $sm-width) {
      display: block;
      margin-bottom: 1.5rem;
    }
  }

  &-title {
    padding-left: 1.5rem;
  }

  &-link-item {
    .nav-link {
      font-size: 1.6rem;
    }

    .btn {
      margin-top: 2rem;
    }

    &.logout {
      .btn {
        font-size: 1.6rem;
        min-width: 220px;
      }
    }
  }
}

.nav-item {
  &.active {
    background: palette(background, hover);

    .nav-label {
      border-bottom-color: palette(border, dark);
    }
  }

  .icon {
    font-size: 2.8rem;
  }
}
