@import "product/quick-view";

.declination {
  margin-bottom: 4rem;

  &-name {
    font-weight: $bold;
    margin-bottom: 0.5rem;
    margin-top: 2rem;
    text-transform: uppercase;
  }

  &-list {
    margin: 0 -0.5rem;
  }

  &-value {
    display: inline-block;
    padding: 0.5rem 1.5rem;
  }

  &-img {
    display: inline-block;
    height: 60px;
    line-height: 0;
    overflow: hidden;
    padding: 0;
    width: 60px;

    img {
      position: relative;
      z-index: -1;
    }
  }

  &-item {
    border: 1px solid palette(border);
    border-radius: $border-radius;
    color: palette(text, light);
    margin: 0 0.5rem 1rem;
    transition: 0.3s;

    &.with-img {
      line-height: 0;
    }

    &.active {
      border-color: palette(border, dark);
      color: $white-color;

      &:not(.with-img) {
        background-color: palette(button);
      }
    }

    &:hover {
      border-color: palette(border, dark);
    }
  }
}

.product {
  &-qty {
    &-input {
      background: transparent;
      border: 0;
      padding: 0;
      text-align: center;
      width: 4rem;

      &:focus {
        outline: 0;
      }
    }

    &-btn {
      cursor: pointer;
      padding: 0 1.5rem;
      transition: 0.1s;

      @media (min-width: $md-width) {
        padding: 0 2rem;

        &:not(.disabled) {
          &:hover {
            transform: scale(1.1);
          }
        }
      }

      &.disabled {
        color: palette(text, light);
      }
    }
  }
}

.video-thumb {
  @media (min-width: $md-width) {
    position: relative;

    &::after,
    &::before {
      @include poa(50%, 0, null, 0);
      transform: translateY(-50%);
    }

    &::after {
      color: palette(text);
      content: icon(play-video);
      font-family: $icon-font;
      font-size: 1.2rem;
      text-align: center;
    }

    &::before {
      background-color: $white-color;
      border-radius: 50%;
      content: "";
      height: 35px;
      margin: 0 auto;
      opacity: 0.8;
      width: 35px;
    }
  }
}

.product-actions {
  .product-add-ctn {
    background-color: palette(button);
    border-radius: 2em;
    height: 4rem;
    padding: 0.5rem 0;
    transition: 0.3s;

    @media (min-width: $md-width) {
      font-size: 1.8rem;
      height: 5rem;
      padding: 1rem 0;
    }

    &:hover {
      background-color: palette(button, dark);
    }

    &-mobile {
      display: none;
    }

    &.stack {
      @include position(fixed, null, 1.5rem, 1.5rem, 1.5rem);
      display: flex;
      z-index: 100;
    }
  }

  .add-to-cart {
    width: 100%;

    @media (min-width: $md-width) {
      .icon {
        transition: 0.5s;
      }

      &:hover {
        .icon {
          padding-left: 1rem;
        }
      }
    }
  }

  .add-to-cart-btn {
    cursor: pointer;
    height: 100%;
    line-height: inherit;
    margin: 0 auto;
    padding: 0;

    .icon {
      display: none;

      @media (min-width: $xs-width) {
        display: block;
        font-size: 2rem;
        margin-left: 2rem;
      }
      @media (min-width: $sm-width) {
        font-size: 2.2rem;
      }
    }
  }

  .product-qty-input,
  .product-qty-btn,
  .add-to-cart-btn {
    color: $white-color;
    font-weight: $bold;
  }

  .product-qty {
    &-ctn {
      border-right: 1px solid palette(border, dark);
      height: 100%;
    }
  }

  .minimal_quantity_wanted_p {
    text-align: center;
    margin-top: 1rem;
  }
}

.product-main {
  border-top: 1px solid palette(border);
  padding: 1.5rem 0 $gutter;

  .product-images {
    margin-bottom: 1rem;

    .img-full {
      cursor: pointer;
      position: relative;
      width: 100%;
    }
  }

  .prices {
    font-size: 2.5rem;

    @media (min-width: $md-width) {
      font-size: 3rem;
    }
  }

  .old-price {
    font-size: 1.6rem;

    @media (min-width: $md-width) {
      font-size: 2rem;
    }
  }

  .product-infos {
    width: 100%;

    &-top {
      margin-bottom: 2rem;
    }

    &-secondary {
      margin-bottom: $gutter;
      margin-top: 2.5rem;

      .alert {
        margin-bottom: 0.5rem;
      }
    }
  }

  .product-name {
    margin: 1rem 0 0.5rem;
  }

  .ekomi-summary-ratings {
    pointer-events: none;
  }

  .reviews-anchor {
    cursor: pointer;
  }

  .product-availability-prices {
    margin-bottom: 2rem;
    margin-top: 1rem;
  }

  &.oos {
    .current-price,
    .old-price {
      color: palette(text, light);
    }
  }
}

.product-complementary {
  .tab-content {
    display: none;

    @media (min-width: $md-width) {
      display: block;
    }
  }

  strong {
    font-weight: $bold;
  }
}

.product-pack {
  margin-bottom: $gutter;

  &-img {
    display: block;
    line-height: 0;
    min-width: 8rem;
  }

  &-item {
    border: 1px solid palette(border);
    margin: 0.5rem 0 1rem;

    &-info {
      padding: 1rem;
    }

    .current-price {
      display: block;
      font-size: 1.8rem;
      margin-top: .5rem;
    }
  }
}

.product-menu {
  background-color: $white-color;
  margin: 0 0 $gutter;
  padding-top: 0.5rem;
  text-align: center;
  z-index: 5;

  &-list {
    background: palette(background);
    border-bottom: 1px solid palette(border);
    margin: 0.5rem -1.5rem 0;
    padding: 1.5rem 1.5rem 1rem;
  }

  &-item {
    padding: 0 1.5rem;

    &:hover {
      .product-menu-link {
        border-bottom-color: palette(border, dark);
      }
    }
  }

  &-link {
    border-bottom: 1px solid transparent;
    font-weight: $bold;
    text-transform: uppercase;
  }

  &-top {
    opacity: 0;
    transition: 0.3s;
  }

  &.stack {
    left: 0;
    position: fixed;
    right: 0;
    top: 0;

    .product-menu-top {
      opacity: 1;
    }
  }

  .add-to-cart {
    margin-left: 3rem;
    width: auto;
  }

  .current-price {
    font-size: 2.2rem;
  }

  .old-price {
    font-size: 1.6rem;
    margin-left: 1rem;
  }

  .default-declination {
    color: palette(text, light);
    font-weight: $bold;
    margin: 0 -0.5rem 0 2rem;

    &-name {
      text-transform: uppercase;
    }

    &-item {
      border: 1px solid palette(border, dark);
      border-radius: $border-radius;
      margin: 0 0.5rem;
      padding: 0.3rem 1rem;
    }
  }
}

.product-description {
  margin-bottom: 0;
}

.product-description,
.product-more {
  .tab-title {
    text-align: left;
  }

  .small-ctn {
    @media (min-width: $md-width) {
      padding: 0;
      width: 63rem;
    }
  }

  .tab-content-section {
    a {
      @extend .link;

      &::after {
        opacity: 1;
      }

      &:hover {
        &::after {
          opacity: 0;
        }
      }
    }

    ul {
      margin-bottom: 1.5rem;

      li {
        &::before {
          content: "\002022";
          margin-right: 5px;
        }
      }
    }
  }
}

.features {
  margin-bottom: 1rem;
  margin-top: 1rem;

  &-line {
    padding: 0.5rem 1.5rem;

    &:nth-of-type(odd) {
      background-color: palette(background);
    }
  }

  &-name {
    font-weight: $bold;
  }
}

.top-features {
  margin-bottom: 1rem;
  min-width: 10rem;
  padding: 0 0.5rem;
  text-align: center;

  &-ctn {
    margin: 0 -0.5rem;
  }

  &-name {
    display: block;
    font-weight: $bold;
    margin-bottom: 1rem;
  }

  &-inner {
    background-color: palette(background);
    border-radius: $border-radius;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding: 1.5rem 0.5rem;
  }

  &-img {
    display: block;
    margin: 0 auto 1rem;
  }
}

.product-attachment {
  &-link {
    background-color: palette(background);
    border-radius: $border-radius;
    margin-bottom: 1.5rem;
    padding: 1.5rem 1.5rem 1.2rem;

    &:hover {
      .product-attachment-name {
        border-bottom-color: palette(border, dark);
      }
    }
  }

  &-name {
    border-bottom: 1px solid transparent;
    font-weight: $bold;
    transition: 0.3s;
  }
}

.slideshow {
  margin: 0 -0.5rem;
  overflow: auto;

  @media (min-width: $md-width) {
    margin: 0;
    overflow: visible;
    padding-bottom: $gutter;
  }

  &-item {
    margin: 0 0.5rem;
    max-width: 300px;
    min-width: 225px;

    @media (min-width: $md-width) {
      margin: 0;
    }
  }
}

.product-more {
  margin: 0;

  @media (min-width: $md-width) {
    padding-top: 1.5rem;
  }

  .tab-content {
    display: none;
    margin-bottom: $gutter;

    @media (min-width: $md-width) {
      margin-bottom: 0;

      &,
      &:not(.hidden) {
        display: block;
      }
    }
  }

  img {
    border-radius: $border-radius;
  }

  &-video {
    padding-top: 1rem;

    @media (min-width: $md-width) {
      padding-top: $gutter;
    }
  }

  &-title {
    font-weight: $bold;
    margin-bottom: 1.5rem;
    text-transform: uppercase;
  }

  &-block {
    padding: ($gutter / 2) 0;

    h3 {
      font-weight: $bold;
      margin-bottom: 0.5rem;
      margin-top: 1.5rem;
      text-transform: uppercase;

      @media (min-width: $md-width) {
        margin-bottom: 1rem;
        margin-top: 0;
      }
    }
  }

  &-mea {
    margin: 0 - $gutter;
    padding-top: $gutter;

    @media (min-width: $md-width) {
      margin: 0;
    }

    &-item {
      padding: ($gutter / 2) 0;

      @media (min-width: $md-width) {
        padding: $gutter 0;
      }

      .row {
        background-color: palette(background);
        margin: 0;
      }

      &:nth-of-type(even) {
        @media (min-width: $md-width) {
          .product-more-mea-img {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;

            &-ctn {
              order: 1;
            }
          }
        }
      }

      &:nth-of-type(odd) {
        @media (min-width: $md-width) {
          .product-more-mea-img {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }
        }
      }
    }

    .p-0 {
      padding: 0;
    }

    &-img {
      &-ctn {
        line-height: 0;
        padding-bottom: 66.66%;
      }
    }

    &-text {
      max-width: 410px;

      &-ctn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 2rem 1.5rem;

        @media (min-width: $md-width) {
          padding-left: 7rem;
        }
      }

      .h2 {
        margin-bottom: 1rem;

        @media (min-width: $md-width) {
          margin-bottom: 1.5rem;
        }
      }

      .btn {
        margin-top: 1.5rem;
      }

      a {
        &::after {
          display: none;
        }
      }
    }
  }
}

.product-selection {
  @media (min-width: $md-width) {
    margin-top: 1.5rem;
    padding: 5rem 0;
  }
}

.product-reviews {
  .tab-header {
    .small-ctn {
      position: relative;
      width: 63rem;
    }
  }

  .tab-content {
    position: relative;
  }
}

.product-recipes {
  @media (min-width: $md-width) {
    margin: $gutter 0;
    padding-bottom: 4rem;
  }

  .tab-content {
    margin: 0 -1.5rem $gutter;
    padding: 0;

    @media (min-width: $md-width) {
      margin: 0;
    }
  }

  &-row {
    background-color: palette(background);
    margin: 0;
  }

  .mea,
  &-item {
    padding: 0;
  }

  .mea {
    margin: 0;

    &-img {
      img {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }

  &-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem 1.5rem;

    @media (min-width: $md-width) {
      padding-left: 7rem;
    }
  }
}

.product-delivery {
  @media (min-width: $md-width) {
    padding: 4rem 0;
  }

  &-item {
    margin-bottom: 4rem;
  }

  ul {
    margin-bottom: 1.5rem;

    li {
      &::before {
        content: "\002022";
        margin-right: 5px;
      }
    }
  }
}

#product_reserved_for_professionals a {
  margin-top: 1rem;
}
