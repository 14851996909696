.page-heading {
  &-inner {
    padding: 1rem 0;

    @media (min-width: $md-width) {
      padding-bottom: 3rem;
    }
    @media (min-width: $lg-width) {
      padding-top: 2rem;
    }
  }

  &-logo {
    display: block;
    margin: 0 auto;
    max-height: 110px;
    max-width: 190px;

    @media (min-width: $md-width) {
      @include poa(10px, 0);
      margin: 0;
      transform: translateY(-50%);
    }
  }

  &-title {
    margin-bottom: 0;
    margin-top: 1rem;
  }

  &-top {
    position: relative;

    @media (min-width: $md-width) {
      margin-bottom: 2.5rem;

      &::after {
        @include poa(null, -100%, -2.5rem, -100%);
        border-bottom: 1px solid palette(border);
        content: "";
      }
    }
  }

  &-desc {
    display: none;

    @media (min-width: $md-width) {
      display: block;
      padding-top: 3rem;
    }

    strong {
      font-weight: $bold;
    }

    a {
      @extend .link-content;
    }
  }

  .btn-back {
    float: left;

    @media (min-width: $md-width) {
      @include poa(0, null, null, 0);
      float: none;
    }
  }

  &.order-process,
  &.authentication {
    .page-heading {
      &-title {
        margin-top: 0;
        position: static;
        transform: none;
      }
    }
  }

  &.authentication {
    margin: 1.5rem 0;

    @media(min-width: $md-width) {
      margin: 3rem 0;
      padding-top: 0;
    }
  }

  &.order-process {
    margin: 1rem 0 3rem;
  }
}
