.promo {
  &-block {
    display: none;
    font-size: 1.4rem;
    font-weight: $bold;
    overflow: auto;
    white-space: nowrap;

    &:nth-of-type(2) {
      display: block;
    }

    @media (min-width: $md-width) {
      display: block;
      font-size: 1.6rem;
    }
  }

  &-link {
    color: $white-color;
  }

  &-subtitle {
    background-color: palette(background, transparency);
    border-radius: 4px;
    display: inline-block;
    padding: 5px 7px 3px;
  }
}
