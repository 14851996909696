.blocklisting {
  position: relative;

  &-link {
    border-radius: $border-radius;
    display: block;
    line-height: 0;
    overflow: hidden;

    &:hover {
      .blocklisting-img {
        transform: scale(1.05);
      }
    }
  }

  &-img {
    transition: 1s;
  }

  &-content {
    @include poa(0, 0, 0, 0);

    padding: $gutter 1rem 1rem;
    text-align: center;

    @media (min-width: $md-width) {
      padding: $gutter * 2 2rem 2rem;
    }
  }

  .btn {
    height: auto;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  &-title {
    color: $white-color;
    display: block;
    font-size: 1.8rem;
    font-weight: $bold;
    line-height: 1;
    margin-bottom: $gutter;

    @media (min-width: $md-width) {
      font-size: 3.5rem;
    }
  }
}

.product-mea {
  &:last-of-type {
    .blocklisting-content {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media (min-width: $md-width) {
        padding-left: 3rem;
        padding-right: 3rem;
      }
    }
  }
}
