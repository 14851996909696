.order-row {
  border: 1px solid palette(border);
  margin: 15px;
  padding: 1.5rem 0;

  @media (min-width: $md-width) {
    margin: 0 0 15px;
    padding: 3.5rem;
  }

  .order-infos {
    @media (min-width: $xs-width) {
      border-right: 1px solid palette(border);
    }

    .order_link {
      font-size: 1.6rem;
      font-weight: $bold;

      .icon {
        font-size: 1.2rem;
        margin-left: 6px;
      }

      .valid {
        color: palette(success);
      }

      .error {
        color: palette(danger);
      }
    }

    .carrier_delay {
      display: block;
    }

    .order_date {
      margin-top: 15px;
    }

    .order-info {
      font-size: 1.4rem;
      line-height: 2rem;

      .label {
        display: inline-block;
        width: 60%;
      }

      .bold {
        font-weight: $bold;
      }
    }
  }

  .order-detail {
    align-items: center;
    display: flex;

    @media (max-width: $md-width) {
      padding-top: 10px;
    }

    img {
      padding: 0 10px;
      width: 28%;
    }

    .btn-plus {
      border-color: palette(border);
      border-radius: 50%;
      display: inline-block;
      font-size: 2.4rem;
      height: 3.8rem;
      line-height: 3.8rem;
      margin-left: 10px;
      padding: 0;
      text-align: center;
      width: 3.8rem;
    }
  }

  .order-links {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: $md-width) {
      padding-top: 15px;
    }

    .link {
      font-weight: $bold;
      margin-top: 15px;
    }

    .btn-stroke {
      &.btn-small {
        border-color: palette(text);
        color: palette(text);
      }

      &:hover {
        color: $white-color;
      }
    }
  }
}
