.sitemap {
  .tree {
    line-height: 25px;

    > .tree-list {
      margin-bottom: 30px;
    }

    .subtree {
      margin-bottom: 1rem;
      padding-left: 25px;

      li {
        position: relative;

        &::before {
          @include poa(null, null, null, -15px);

          content: '\002022';
          font-family: $icon-font;
          margin-right: 5px;
        }
      }
    }
  }
}
