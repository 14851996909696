.matrice,
.advcms-content {
  strong {
    font-weight: $bold;
  }

  em {
    font-style: italic;
  }

  a:not(.btn) {
    border-bottom: 0;
    color: inherit;
    display: inline-block;
    position: relative;

    &::after {
      @include poa(null, 0, 2px, 0);

      border-bottom: .5px solid palette(text, light);
      content: "";
      height: 1px;
      opacity: 0;
      transition: .1s;
      width: 100%;
    }

    &:hover {
      &::after {
        opacity: 1;
      }
    }

    &::after {
      opacity: 1;
    }

    &:hover {
      &::after {
        opacity: 0;
      }
    }
  }

  ul,
  ol {
    margin-bottom: 1.5rem;
    margin-left: 1.5rem;
    padding-left: 3rem;
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }

  p {
    margin-bottom: 0;
    padding-bottom: 1.5rem;
  }
}
