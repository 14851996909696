@font-face {
  font-display: swap;
  font-family: "Amatic SC";
  font-style: normal;
  font-weight: 700;
  src: url("../../fonts/amaticsc/amaticsc-bold-webfont.woff2") format("woff2"),
    url("../../fonts/amaticsc/amaticsc-bold-webfont.woff") format("woff");
}
@font-face {
  font-display: swap;
  font-family: "proxima-nova";
  font-style: normal;
  font-weight: 600;
  src: url("../../fonts/proxima-nova/proxima_nova_semibold.woff2") format("woff2"),
    url("../../fonts/proxima-nova/proxima_nova_semibold.woff") format("woff");
}
@font-face {
  font-display: swap;
  font-family: "proxima-nova";
  font-style: italic;
  font-weight: 600;
  src: url("../../fonts/proxima-nova/proxima_nova_semibold_italic.woff2") format("woff2"),
    url("../../fonts/proxima-nova/proxima_nova_semibold_italic.woff") format("woff");
}
@font-face {
  font-display: swap;
  font-family: "proxima-nova";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/proxima-nova/proxima_nova_regular.woff2") format("woff2"),
    url("../../fonts/proxima-nova/proxima_nova_regular.woff") format("woff");
}
@font-face {
  font-display: swap;
  font-family: "proxima-nova";
  font-style: italic;
  font-weight: 400;
  src: url("../../fonts/proxima-nova/proxima_nova_regular_italic.woff2") format("woff2"),
    url("../../fonts/proxima-nova/proxima_nova_regular_italic.woff") format("woff");
}
