.index {
  &-top {
    background-color: palette(background);
  }

  .selection {
    margin-top: 4rem;

    @media (min-width: $md-width) {
      margin-top: 9rem;
    }
  }

  .other-selection {
    margin-bottom: 3rem;
  }
}

.site-reviews {
  border-top: 1px solid palette(border);
  padding-bottom: 1.5rem;
  padding-top: 3rem;

  @media (min-width: $md-width) {
    padding-top: 6rem;
  }

  &-ctn {
    padding-bottom: 1rem;
    position: relative;
  }

  &-header {
    @media (min-width: $sm-width) {
      position: relative;
    }
  }

  &-title {
    @media (min-width: $md-width) {
      margin-bottom: 3.5rem;
      margin-left: 20rem;
      margin-right: 20rem;
    }
  }

  &-link {
    text-align: center;

    @media (min-width: $sm-width) {
      @include poa(-.5rem, 0);
    }

    @media (min-width: $md-width) {
      top: .5rem;
    }
  }
}
