.product-reinsurance {
  padding-top: 2rem;

  .product & {
    border-top: 1px solid palette(border);
    margin-top: $gutter / 2;
  }

  &-item {
    margin-bottom: 0.5rem;
  }

  &-img-ctn {
    text-align: center;
  }
}
