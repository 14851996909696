.manufacturer {
  .letter {
    background-color: palette(background);
    border-radius: $border-radius;
    font-weight: $bold;
    margin-top: $gutter;
    padding: 1.5rem;
    text-align: center;
  }

  .manufacturer {
    &-list {
      margin: 0;

      @media (min-width: $md-width) {
        margin-left: -1.5rem;
        margin-right: -1.5rem;
      }
    }

    &-item {
      margin: $gutter 0;
      padding: 0 0.5rem;

      @media (min-width: $md-width) {
        padding: 0 1.5rem;
      }
    }

    &-link {
      display: block;
      width: 100%;
    }

    &-img {
      max-height: 6rem;
      max-width: 10rem;

      &-ctn {
        align-items: center;
        border: 1px solid palette(border);
        display: flex;
        flex-direction: column;
        height: 8.2rem;
        justify-content: center;
        margin-bottom: 1.5rem;
        padding: 1rem;
        width: 100%;
      }
    }
  }
}

.manufacturer-name {
  font-weight: $bold;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
}
