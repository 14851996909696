@import '../../_communs/_k2000/wysiwyg';

.matrice {
  margin-bottom: 4rem;

  @media (min-width: $md-width) {
    margin-bottom: 6rem;
  }

  &.media {
    margin-bottom: 0;

    @media (min-width: $md-width) {
      margin-bottom: 3rem;
    }
  }

  &.faq {
    margin-bottom: 0;

    .h5 {
      margin-right: 1.5rem;
    }
  }

  &-media {
    margin-bottom: 2rem;

    @media (min-width: $md-width) {
      margin-bottom: 0;
    }

    &-ctn {
      line-height: 0;
    }
  }

  &-action {
    margin-top: 2rem;
  }
}
