.block {
  margin-bottom: 3rem;

  &-relatif {
    position: relative;
  }
}

.fh {
  height: 100%;
}

.fw {
  width: 100%;
}

.nw {
  white-space: nowrap;
}

.text-center {
  text-align: center;
}

.img-responsive,
img {
  height: auto;
  max-width: 100%;
}

.cover {
  background-size: cover;
}

.valign-middle {
  > * {
    display: inline-block;
    float: none;
    vertical-align: middle;
  }
}

.big-ctn {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  width: 1630px;
}

.large-ctn {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  width: 1130px;
}

.medium-ctn {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  width: 85rem;
}

.small-ctn {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  width: 63rem;
}

.xs-ctn {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  width: 41rem;
}

.mention {
  font-size: 1.2rem;
  margin-top: .5rem;

  @media (min-width: $md-width) {
    font-size: 1.4rem;
  }
}

.unvisible {
  display: none;
}

// styles //
.underline {
  text-decoration: underline;
}

.text-right {
  text-align: right;
}

.bold {
  font-weight: $bold;
}

///
.link {
  border-bottom: 0;
  display: inline-block;
  position: relative;

  &::after {
    @include poa(null, 0, 2px, 0);

    border-bottom: .5px solid palette(text, light);
    content: "";
    height: 1px;
    opacity: 0;
    transition: .1s;
    width: 100%;
  }

  &:hover {
    &::after {
      opacity: 1;
    }
  }
}

///
.link-content {
  @extend .link;

  &::after {
    opacity: 1;
  }

  &:hover {
    &::after {
      opacity: 0;
    }
  }
}

.anchor {
  &-link {
    border-bottom: 1px solid palette(text);
    font-weight: $bold;

    &:hover {
      border-color: transparent;
      color: palette(text, light);
    }
  }
}

.cms-content {
  li {
    margin-bottom: 1rem;
  }

  ul {
    margin-left: 2.5rem;

    ul {
      margin-top: 1rem;
    }
  }

  p {
    margin-bottom: 2.5rem;
  }

  em {
    font-style: italic;
  }
}

// video iframe //
.video-ctn {
  margin-bottom: $gutter / 2;
  padding-bottom: 56.25%;
  position: relative;

  @media (min-width: $md-width) {
    margin-bottom: $gutter;
  }

  iframe {
    border: 0;
    border-radius: $border-radius;
    height: 100%;
    left: 0;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

sup {
  font-size: .6em;
  vertical-align: super;
}

///
.no-scrollbar {
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0 !important;
  }
}

///
.question-mark {
  border: 1px solid palette(border, dark);
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 1.7rem;
  margin-left: .2rem;
  text-align: center;
  transition: .3s;
  width: 1.7rem;

  @media (min-width: $md-width) {
    height: 2rem;
    margin-left: 1rem;
    width: 2rem;
  }

  &:hover {
    color: palette(text);
  }
}

.alias {
  display: block;
  font-weight: bold;
}

.error {
  margin-bottom: 3rem;
}

///
.alert {
  border-radius: $border-radius;
  display: inline-block;
  font-weight: $bold;
  max-width: 100%;
  padding: .5rem 1rem;

  a {
    border-color: inherit;
    color: inherit;
  }

  &-infos {
    background-color: palette(primary, background);
    color: palette(primary);
  }

  &-success {
    background-color: palette(success, background);
    color: palette(success);
  }

  &-warning {
    background-color: palette(warning, background);
    color: palette(warning);
  }

  &-danger {
    background-color: palette(danger, background);
    color: palette(danger);
  }
}

///
.help-block {
  max-width: 300px;

  &-content {
    font-size: 1.4rem;
  }
}

.hidden {
  display: none;
}

.spinner {
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  text-align: center;
  top: 10%;
  width: 70px;
  z-index: 1;

  & > div {
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    background-color: palette(primary);
    border-radius: 100%;
    display: inline-block;
    height: 18px;
    width: 18px;
  }

  .bounce1 {
    animation-delay: -.32s;
  }

  .bounce2 {
    animation-delay: -.16s;
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}

.lazy-ctn {
  position: relative;

  .lazy-img {
    @include poa(0, 0, 0, 0);
    width: 100%;
  }
}
