.breadcrumb {
  color: palette(text, light);
  display: flex;
  flex-wrap: nowrap;
  font-size: 1.2rem;
  margin-bottom: 10px;
  overflow: auto;
  white-space: nowrap;

  @media (min-width: $lg-width) {
    margin-bottom: 0;
  }

  @media (min-width: $xl-width) {
    font-size: 1.4rem;
  }

  &-item {
    &:not(.last)::after {
      content: icon(arrow-right);
      font-family: $icon-font;
      font-size: 8px;
      padding: 0 .5rem;

      @media (min-width: $xl-width) {
        padding: 0 1rem;
      }
    }
  }

  &-link {
    color: palette(text, light);

    &:hover {
      color: palette(text);
    }
  }
}

.product {
  .breadcrumb {
    position: static;
    transform: none;

    @media (min-width: $md-width) {
      padding: 2rem 0 3rem;
    }
  }
}

.navigation-pipe {
  display: inline-block;
  font-size: 8px;
  padding: 0 .5rem;

  @media (min-width: $xl-width) {
    padding: 0 1rem;
  }
}
