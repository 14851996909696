.alternate {
  margin-bottom: 3.5rem;
  margin-top: 3.5rem;

  @media (min-width: $md-width) {
    margin-bottom: 7rem;

    &:hover {
      .alternate-img {
        transform: scale(1.05);
      }
    }
  }

  &:nth-of-type(even) {
    .alternate-infos {
      @media (min-width: $md-width) {
        order: -1;
      }
    }
  }

  &-img {
    transition: 1s;
  }

  .alternate-img-ctn {
    line-height: 0;
    margin-bottom: 1.7rem;
    overflow: hidden;
    padding-bottom: 69.1%;
    text-align: center;

    @media (min-width: $md-width) {
      margin-bottom: 0;
      padding-bottom: 69.84%;
    }
  }

  &-content-ctn {
    margin: 0 auto;
    max-width: 630px;
    padding: 0;

    @media (min-width: $md-width) {
      max-width: 100%;
      padding: 0 15px;
    }

    @media (min-width: $lg-width) {
      max-width: 410px;
    }
  }

  &-title {
    text-align: left;
  }

  &-btn {
    margin-top: 2rem;

    @media (min-width: $md-width) {
      margin-top: 3rem;
    }
  }
}
