////
/// @group Buttons
/// @name buttons
/// @example
/// .btn
/// .btn-stroke
/// .btn-light
/// .btn-large (may be combined)
/// .btn-small (may be combined)
/// .btn-fw (may be combined)
////

.btn-unstyled {
  background: transparent;
  border: 0;
  border-radius: 0;
  font-family: $font-family;
  font-size: 1em;
  line-height: 1;
}

button {
  &:focus {
    outline: 0;
  }
}

///
.btn {
  background: palette(button);
  border: 1px solid palette(button);
  border-radius: 2em;
  color: $white-color;
  cursor: pointer;
  display: inline-block;
  font-family: $font-family;
  font-size: 1.6rem;
  font-weight: $bold;
  line-height: 1;
  min-height: 40px;
  padding: 10px 20px;
  position: relative;
  transition: .3s;

  &:focus {
    outline: 0;
  }

  @media (min-width: $md-width) {
    font-size: 1.8rem;
    min-height: 50px;
    padding: 15px 30px;
  }

  &:hover {
    background-color: palette(button, dark);
    text-decoration: none;
  }

  &-small {
    font-size: 1.4rem;
    min-height: 30px;
    padding: 6px 30px;

    @media (min-width: $md-width) {
      font-size: 1.6rem;
    }
  }

  &-light {
    background-color: palette(button, light);
    border: 0;
    color: palette(text);

    &:hover {
      background-color: $white-color;
    }
  }

  &-stroke,
  &-default {
    background-color: transparent;
    color: palette(text);

    &.btn-small {
      border-color: palette(text, light);
      color: palette(text, light);
    }

    &:hover {
      background-color: palette(button);
      color: $white-color;
    }
  }

  &-large {
    padding-left: 30px;
    padding-right: 30px;

    @media (min-width: $md-width) {
      padding-left: 60px;
      padding-right: 60px;
    }
  }

  &-fw {
    text-align: center;
    width: 100%;
  }

  &.disabled {
    opacity: .5;
  }
}

.btn-back {
  align-items: center;
  border: 1px solid palette(border);
  border-radius: 50%;
  display: flex;
  height: 30px;
  justify-content: center;
  width: 30px;

  @media (min-width: $md-width) {
    height: 40px;
    width: 40px;
  }

  .icon {
    color: palette(text, light);
    font-size: 10px;
  }

  &:hover {
    border-color: palette(border, dark);
  }
}
