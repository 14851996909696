.lSSlideOuter {
  .lSPager {
    &.lSpg {
      @include poa(null, 0, 15px, 0);

      > li {
        a {
          background-color: palette(border);
        }

        &.active,
        &:hover {
          a {
            background-color: palette(border, dark);
          }
        }
      }
    }

    &.lSGallery {
      li {
        align-items: center;
        border: 1px solid transparent;
        border-radius: $border-radius;
        display: flex;
        flex-direction: column;
        height: 8rem;
        justify-content: center;
        transition: .3s;
        width: 8rem;

        &.active {
          border-color: palette(border);
        }

        &:hover {
          border-color: palette(border, dark);
        }
      }
    }
  }
}

.lg-outer {
  .lg {
    border-radius: $border-radius;
    box-shadow: 0 0 43px palette(shadow);
    height: 900px;
    max-height: 100%;
    max-width: 100%;
    position: relative;
    width: 900px;
  }

  .lg-inner {
    background-color: $white-color;
  }

  .lg-sub-html {
    background-color: transparent;
    color: palette(text);
    position: absolute;
  }

  .lg-image {
    max-height: 90%;
  }
}

.lg-thumb-outer,
.lg-toogle-thumb,
.lg-download {
  display: none;
}

.lg-toolbar {
  background: none;

  .lg-icon {
    color: palette(text, light);

    &:hover {
      color: palette(text);
    }
  }

  .lg-close {
    font-size: 1.6rem;

    &::after {
      content: icon(close);
    }
  }
}

.lg-actions {
  .lg-next,
  .lg-prev {
    background: none;
    color: palette(text, light);
    font-size: 3rem;

    &:hover {
      color: palette(text);
    }
  }

  .lg-next {
    &::before {
      content: none;
    }

    &::after {
      content: icon(arrow-right);
    }
  }

  .lg-prev {
    &::after {
      content: icon(arrow-left);
    }
  }
}

#lg-zoom-in {
  &::after {
    content: '+';
  }
}

#lg-zoom-out {
  &::after {
    content: '-';
  }
}

.lg-backdrop {
  background-color: palette(shadow, overlay);
}

.slider-video {
  padding-bottom: 33.333%;
}
