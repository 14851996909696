// Header //
.header {
  &-main {
    transition: .3s;
    z-index: 150;

    @media (min-width: $md-width) {
      left: 0;
      position: fixed;
      right: 0;
      top: 0;

      .product & {
        position: absolute;
      }
    }
  }

  &-links {
    display: none;
    width: 50%;

    @media (min-width: $sm-width) {
      display: block;
      float: left;
    }

    li {
      display: inline-block;
      padding-right: 12px;
      position: relative;
    }
  }

  &-text {
    color: $white-color;
    display: block;
    text-align: center;
    width: 100%;

    @media (min-width: $sm-width) {
      float: right;
      text-align: right;
      width: 50%;
    }
  }

  &-top {
    background: palette(background);
    height: $header-size-unstack;
    padding: 10px 0;
    position: relative;
    transition: .3s;

    @media (min-width: $md-width) {
      height: $header-size-unstack-md;
      padding: $gutter 0;
    }

    @media (min-width: $lg-width) {
      height: $header-size-unstack-lg;
    }

    &-left {
      transition: .3s;
    }
  }

  &-search {
    @media (min-width: $sm-width) and (max-width: $md-width) {
      order: 1;
    }

    &-form {
      margin-top: 1.5rem;

      @media (min-width: $md-width) {
        margin-top: 0;
      }
    }

    .order-cart:not(.menu-open) & {
      display: none;

      @media (min-width: $md-width) {
        display: block;
      }
    }
  }

  &-logo {
    text-align: center;

    &-img {
      display: block;
      margin: 0 auto;
      max-width: 100%;
      transform-origin: left;
      transition: .3s;
      width: 163px;

      @media (min-width: $sm-width) {
        width: 235px;
      }

      @media (min-width: $md-width) {
        margin: 0;
        width: 250px;
      }

      @media (min-width: $lg-width) {
        width: 260px;
      }

      @media (min-width: $xl-width) {
        width: 285px;
      }
    }
  }

  &-top-left {
    .header-cart {
      @media (min-width: $sm-width) {
        display: none;
      }
    }
  }

  &-top-right {
    @include poa($header-size-unstack-xxs + $banner-size-unstack - 92.5px, 0, null, -100%);

    background-color: palette(background);
    border-bottom: 1px solid palette(border);
    border-top: 1px solid palette(border);
    margin-top: 1rem;
    padding: 1rem 1.5rem;
    transition: .3s;
    z-index: 10;

    @media (min-width: $xs-width) {
      top: $header-size-unstack-xs + $banner-size-unstack - 95px;
    }

    @media (min-width: $sm-width) {
      border: 0;
      left: unset;
      margin: 0;
      padding-bottom: 0;
      padding-top: 0;
      position: static;
      right: unset;
      top: unset;
      z-index: unset;
    }

    @media (min-width: $lg-width) {
      padding-left: 4.5rem;
    }

    .header-cart {
      display: none;

      @media (min-width: $sm-width) {
        display: block;
      }
    }

    &-inner {
      position: relative;
    }
  }

  &-icon {
    font-size: 14px;
    position: relative;

    &:first-child {
      margin: 0;
    }

    &-link {
      color: palette(text, light);
    }

    &:hover {
      .header-icon-link {
        color: palette(text);
      }
    }

    .icon {
      display: block;
      font-size: 20px;
      text-align: center;

      @media (min-width: $xs-width) {
        font-size: 23px;
      }
    }

    .header-pin {
      @include poa(-4px, -3px, null, null);

      background: palette(primary);
      border-radius: 50%;
      color: $white-color;
      font-size: 10px;
      font-weight: $bold;
      height: 16px;
      line-height: 1.7em;
      text-align: center;
      transition: .2s;
      width: 16px;

      @media (min-width: $md-width) {
        right: 3px;
        top: -2px;
      }

      &.empty {
        opacity: 0;
      }
    }

    .header-label {
      display: block;
      font-size: inherit;
      font-weight: $bold;
      margin-top: .5rem;

      @media (min-width: $sm-width) {
        display: none;
      }

      @media (min-width: $md-width) {
        display: block;
      }
    }
  }

  &-cart {
    @include poa(1.5rem, 1.5rem);

    .header-label {
      display: none;

      @media (min-width: $md-width) {
        display: block;
      }
    }

    @media (min-width: $sm-width) {
      position: relative;
      right: auto;
      top: auto;
    }
  }

  &-user,
  &-wishlist {
    width: 50%;

    @media (min-width: $sm-width) {
      width: auto;
    }

    .header-icon-link {
      align-items: center;
      display: flex;
      font-size: 1.6rem;
      justify-content: center;
      padding: .5rem 0;

      @media (min-width: $sm-width) {
        display: block;
        font-size: 1.4rem;
        padding: 0;
      }
    }

    .icon {
      color: palette(primary);
      margin-right: 1rem;

      @media (min-width: $sm-width) {
        color: palette(text, light);
        margin-right: 0;
      }
    }

    &:hover {
      .icon {
        color: palette(text);
      }
    }
  }

  &-user {
    border-left: 1px solid palette(border);

    @media (min-width: $sm-width) {
      border: 0;
      position: relative;
    }

    .header-label {
      margin-top: .3rem;
    }

    .icon {
      font-size: 22px;

      @media (min-width: $md-width) {
        font-size: 25px;
      }
    }
  }
}

//@media (max-width: $md-width + 1) {
//  .header-stack {
//    .header-main {
//      top: -$header-size-stack-xxs;
//    }
//  }
//
//  .top {
//    &.header-stack {
//      .stack {
//        top: 0;
//      }
//
//      .header-top-left {
//        padding-left: 1.5rem !important;
//      }
//    }
//  }
//}
@media (min-width: $md-width) {
  // Header-stck //
  .header-stack {
    &:not(.product-desktop) {
      .overtop {
        height: 0;
      }

      &.index {
        .advmenu {
          border-color: palette(border);
        }
      }

      .header-top-left {
        padding-left: 65px;

        @media (max-width: $sm-width) {
          padding-left: 0;
        }
      }

      .header-top {
        height: $header-size-stack-xxs;
        padding: 10px 0;

        @media (min-width: $md-width) {
          height: $header-size-stack;
        }
      }

      .header-logo-img {
        transform: scale(.835);
      }

      .advmenu-root-item {
        &::after {
          bottom: -10px;
        }

        &:hover {
          .advmenu-root-item-content {
            &::after {
              bottom: -6px;
            }
          }
        }

        &-content {
          padding-bottom: 1rem;
        }
      }

      .site-content {
        padding-top: $header-size-stack-xxs;

        @media (min-width: $md-width) {
          padding-top: $header-size-stack;
        }
      }

      .advsubmenu {
        top: 3rem;
      }
    }
  }
}
