.account {
  &-block {
    background-color: $white-color;
    border-radius: $border-radius;
    box-shadow: 0 0 43px palette(shadow);
    font-weight: $bold;
    overflow: auto;
    padding: 1.5rem;
    text-align: center;
    width: 330px;

    &-ctn {
      display: none;
      padding-top: 3rem;
      position: absolute;
      right: 0;
      top: 100%;
      visibility: hidden;
      z-index: 600;
    }

    &::before {
      @include poa(0, 0, null, 0);
      content: '';
      height: 2rem;
    }

    &-header {
      padding: 1rem 0 2rem;
    }

    &-footer {
      border-top: 1px solid palette(border);
      margin-top: 1rem;
      padding-top: 1rem;
      text-align: center;

      &-link {
        line-height: 1.1;
        min-height: 40px;
        padding-top: 1rem;

        &:last-of-type {
          color: palette(text, light);
          display: block;
          padding: 2rem 0 1rem;

          &:hover {
            color: palette(text);
          }
        }
      }
    }
  }
}

@media (min-width: $md-width) {
  .header-user {
    &:hover {
      .account-block-ctn {
        display: block;
        visibility: visible;
      }
    }
  }
}
