.pagination {
  margin-top: $gutter;

  @media (min-width: $md-width) {
    padding: $gutter 0;
  }

  .truncate {
    margin-left: 1rem;
  }

  &-ctn {
    margin: 0 auto;
    overflow: auto;

    @media (min-width: $md-width) {
      overflow: visible;
    }
  }

  &-item,
  &-nav {
    &,
    a {
      color: palette(text, light);
      font-weight: $bold;
    }
  }

  &-item {
    border: 1px solid palette(border);
    border-radius: 50%;
    height: 40px;
    line-height: 40px;
    margin: 0 0 0 1rem;
    width: 40px;

    span {
      display: block;
      height: 100%;
      width: 100%;

      &.hide-xxs {
        display: none;
      }

      &.show-md {
        @media (min-width: $md-width) {
          display: block;
        }
      }
    }

    &:hover,
    &.current {
      border-color: palette(border, dark);
    }
  }

  &-nav {
    margin: 0;
    text-transform: uppercase;

    @media (min-width: $md-width) {
      border: 0;
      width: auto;
    }

    a:hover {
      color: palette(text);
    }

    .disabled {
      display: block;
      opacity: .2;
    }
  }

  &-prev {
    @media (min-width: $xs-width) {
      margin-right: .5rem;
    }

    @media (min-width: $md-width) {
      margin-right: $gutter;
    }
  }

  &-next {
    margin-left: 1rem;

    @media (min-width: $xs-width) {
      margin-left: 1.5rem;
    }

    @media (min-width: $md-width) {
      margin-left: $gutter;
    }
  }
}
