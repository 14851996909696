@font-face {
  font-display: block;
  font-family: "icomoon";
  font-style: normal;
  font-weight: normal;
  src: url("../../fonts/icon/icomoon.woff2?rxbwon") format("woff2"),
    url("../../fonts/icon/icomoon.woff?rxbwon") format("woff"),
    url("../../fonts/icon/icomoon.svg?rxbwon#icomoon") format("svg");
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: $icon-font !important;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;

  /* Better Font Rendering =========== */
  speak: none;
  text-transform: none;
}

.icon-slip:before {
  content: "\e929";
}

.icon-hours:before {
  content: "\e928";
}

.icon-blog:before {
  content: "\e927";
}

.icon-reinsurance3x:before {
  content: "\e901";
}

.icon-account-promo:before {
  content: "\e902";
}

.icon-services:before {
  content: "\e903";
}

.icon-quality:before {
  content: "\e904";
}

.icon-delivery:before {
  content: "\e905";
}

.icon-loyalty:before {
  content: "\e906";
}

.icon-sponsorship:before {
  content: "\e907";
}

.icon-secured-paiement:before {
  content: "\e908";
}

.icon-return:before {
  content: "\e909";
}

.icon-account:before {
  content: "\e90a";
}

.icon-orders:before {
  content: "\e90b";
}

.icon-address:before {
  content: "\e90c";
}

.icon-cart:before {
  content: "\e90d";
}

.icon-maps:before {
  content: "\e90e";
}

.icon-instagram:before {
  content: "\e90f";
}

.icon-facebook:before {
  content: "\e910";
}

.icon-empty-heart:before {
  content: "\e911";
}

.icon-heart:before {
  content: "\e912";
}

.icon-eye:before {
  content: "\e900";
}

.icon-help:before {
  content: "\e913";
}

.icon-help-hover:before {
  content: "\e914";
}

.icon-list-view:before {
  content: "\e915";
}

.icon-menu:before {
  content: "\e916";
}

.icon-search:before {
  content: "\e917";
}

.icon-star:before {
  content: "\e918";
}

.icon-youtube:before {
  content: "\e919";
}

.icon-play-video:before {
  content: "\e91a";
}

.icon-download:before {
  content: "\e91b";
}

.icon-attachment:before {
  content: "\e91c";
}

.icon-arrow-up:before {
  content: "\e91d";
}

.icon-arrow-right:before {
  content: "\e91e";
}

.icon-arrow-down:before {
  content: "\e91f";
}

.icon-arrow-left:before {
  content: "\e920";
}

.icon-check:before {
  content: "\e921";
}

.icon-dropdown-open:before {
  content: "\e922";
}

.icon-dropdown-closed:before {
  content: "\e923";
}

.icon-close:before {
  content: "\e924";
}

.icon-accordion-closed:before {
  content: "\e925";
}

.icon-accordion-open:before {
  content: "\e926";
}
