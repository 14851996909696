.long-desc {
  line-height: 1.4;
  padding: $gutter 0;

  strong {
    font-weight: $bold;
  }

  a {
    border-bottom: 0;
    display: inline-block;
    position: relative;

    &::after {
      @include poa(null, 0, 2px, 0);

      border-bottom: .5px solid palette(text, light);
      content: "";
      height: 1px;
      opacity: 0;
      transition: .1s;
      width: 100%;
    }

    &::after {
      opacity: 1;
    }

    &:hover {
      &::after {
        opacity: 0;
      }
    }
  }

  .matrices {
    margin-top: 3rem;
  }
}

.best-sellers {
  border-bottom: 1px solid palette(border);
  margin-bottom: 2.5rem;
  margin-top: 2.5rem;

  @media (min-width: $md-width) {
    padding-right: 3rem;
  }

  .product-infos-top {
    flex-wrap: wrap;
  }
}

// fix for ie
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .category #forIE {
    display: block;
  }
}

.owl-best-sales {
  @media (min-width: $md-width) {
    margin-left: -1.5rem;
  }

  .owl-stage-outer {
    overflow: visible;

    @media (min-width: $md-width) {
      overflow: hidden;
    }
  }

  .owl-nav {
    right: -2.5rem;
    top: 33.33%;
  }

  .availability-status {
    display: none;
  }

  .product-manufacturer {
    margin-top: .5rem;
  }

  .product-infos-top {
    height: 36px;
  }

  .ekomi-container {
    height: 15px;
    order: -1;
    width: 100%;

    .total-reviews {
      display: none;
    }
  }

  .product-ctn-footer {
    border-radius: $border-radius;
    padding-left: 0;
  }
}

.filters-subcategories {
  margin-bottom: 1.5rem;

  @media (min-width: $md-width) {
    margin-bottom: 2rem;
  }

  &-mobile {
    &-item {
      margin-bottom: 1.2rem;
    }

    &-list {
      margin-bottom: 1rem;
    }
  }

  &-list {
    margin: 0 -2rem;
    overflow: auto;

    @media (min-width: $md-width) {
      flex-direction: column;
      margin: 0;
    }
  }

  &-item {
    padding: 0 .5rem;

    @media (min-width: $md-width) {
      padding: 0;
    }
  }

  &-title {
    display: none;

    @media (min-width: $md-width) {
      border-bottom: 1px solid palette(text);
      display: inline-block;
      font-weight: $bold;
      margin-bottom: 1rem;
      text-transform: uppercase;
    }
  }

  p {
    line-height: inherit;
  }

  &-link,
  a {
    border: 1px solid palette(button);
    border-radius: 2em;
    display: inline-block;
    font-weight: normal;
    padding: .5rem 1.5rem;
    white-space: nowrap;

    @media (min-width: $md-width) {
      border: 0;
      border-bottom: 1px solid transparent;
      border-radius: 0;
      color: palette(text);
      height: auto;
      margin-bottom: 1rem;
      padding: 0;

      &:hover {
        background-color: transparent;
        border-bottom: 1px solid palette(text);
        color: palette(text, light);
      }
    }
  }
}

.products-list {
  margin: 1.5rem 0 $gutter;

  @media (min-width: $md-width) {
    position: relative;
  }

  &-row {
    margin: 0 -.5rem;

    @media (min-width: $md-width) {
      margin: 0 -1.5rem;
    }
  }

  .mobile &-header {
    border-bottom: 1px solid palette(border);
    border-top: 1px solid palette(border);
    margin-bottom: 2.5rem;
    padding: 1rem 0;
  }

  &-header {
    @media (min-width: $md-width) {
      border: 0;
      padding: 0;
    }
  }

  .product-ctn {
    margin-bottom: 6rem;
  }
}

.open-filters {
  color: palette(text, light);
  font-weight: $bold;
  padding: .8rem 0;

  &.has-filters {
    color: palette(success);
  }

  .mobile & {
    font-size: 1.8rem;
    width: 100%;
  }
}

.filters-btn {
  border-left: 1px solid palette(border);
  order: 1;
}

.product-sort {
  color: palette(text, light);

  @media (min-width: $md-width) {
    flex-basis: auto;
    flex-grow: 0;
    order: 1;
    padding-top: 1.5rem;
  }

  &-form {
    position: relative;
  }

  .select {
    padding: .8rem 0;

    @media (min-width: $md-width) {
      padding: 0;
    }
  }

  .custom-select {
    position: static;
    text-align: left;

    span {
      border: 0;
      color: inherit;
      font-weight: $bold;
      height: auto;
      line-height: initial;
      min-height: auto;
      padding-left: 0;

      @media (min-width: $md-width) {
        font-weight: normal;
        padding-left: 1rem;
      }

      &::after {
        display: none;

        @media (min-width: $md-width) {
          display: block;
          margin-right: 0;
        }
      }
    }
  }
}

.mobile {
  .product-sort {
    align-self: center;
    font-size: 1.8rem;
    padding-top: 0;
    width: 100%;

    .select {
      padding: 0;
    }

    .custom-select {
      width: 100%;

      span {
        display: block;
        font-weight: $bold;
        padding: .8rem 0;
        text-align: center;
        width: 100%;

        &::after {
          display: none;
        }
      }
    }
  }
}

.ajax-loader {
  margin-bottom: 1rem;

  span {
    margin-left: 1rem;
  }
}

.category {
  &-desc {
    display: none;

    @media (min-width: $md-width) {
      display: block;
    }

    strong {
      font-weight: $bold;
    }
  }
}

.sibling-categories {
  background-color: palette(background);
  margin-left: -15px;
  margin-right: -15px;
  padding: 40px 0;

  @media (min-width: $sm-width) {
    margin-left: 0;
    margin-right: 0;
    padding: 40px;
  }

  .h4 {
    padding: 0 15px;

    @media (min-width: $sm-width) {
      padding: 0;
    }
  }

  &-wrapper {
    overflow-x: scroll;

    @media (min-width: $sm-width) {
      margin-left: -15px;
      margin-right: -15px;
    }
  }

  &-list {
    @media (min-width: $sm-width) {
      flex-wrap: wrap;
    }
  }

  &-item {
    margin-top: 20px;
    padding: 0 15px;
  }

  &-link {
    white-space: nowrap;
  }
}
