$container: map-get(map-get($breakpoints, xl), container);

.ctn {
  width: $container + 30;

  @media (min-width: $md-width) {
    width: $container + 50;
  }

  @media (min-width: $lg-width) {
    width: $container + 80;
  }
}

.ctn,
.ctn-fluid {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  @media (min-width: $md-width) {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  @media (min-width: $lg-width) {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

.flex,
.col,
.row {
  box-sizing: border-box;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  justify-content: space-between;
}

.row {
  flex-direction: row;

  &.reverse {
    flex-direction: row-reverse;
  }
}

.col {
  flex-direction: column;

  &.reverse {
    flex-direction: column-reverse;
  }
}

.wrap {
  flex-wrap: wrap;
}

.nowrap {
  flex-wrap: nowrap;
}

@mixin flex-styles($breakpoint, $data, $gutter) {
  $container: map-get($data, container);
  $gutter: map-get($data, gutter);

  .row,
  .col {
    margin-left: -#{$gutter/2};
    margin-right: -#{$gutter/2};
  }

  .row-#{$breakpoint} {
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .col-#{$breakpoint} {
    flex-direction: column;
  }

  @for $i from 1 through $grid-columns {
    .#{$breakpoint}#{$i},
    .offset-#{$breakpoint}#{$i} {
      @extend %flex-global-styles-#{$breakpoint};
    }

    .#{$breakpoint}#{$i} {
      flex-basis: #{$column-width * $i+'%'};
      max-width: #{$column-width * $i+'%'};

    }

    .offset-#{$breakpoint}#{$i} {
      margin-left: #{$column-width * $i+'%'};
    }
  }

  .#{$breakpoint} {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    padding-left: #{$gutter/2};
    padding-right: #{$gutter/2};
  }

  .start-#{$breakpoint} {
    justify-content: flex-start;
    text-align: start;
  }

  .center-#{$breakpoint} {
    justify-content: center;
    text-align: center;
  }

  .end-#{$breakpoint} {
    justify-content: flex-end;
    text-align: end;
  }

  .around-#{$breakpoint} {
    justify-content: space-around;
  }

  .between-#{$breakpoint} {
    justify-content: space-between;
  }

  .top-#{$breakpoint} {
    align-items: flex-start;
    align-self: start;
  }

  .middle-#{$breakpoint} {
    align-items: center;
    align-self: center;
  }

  .bottom-#{$breakpoint} {
    align-items: flex-end;
    align-self: end;
  }

  .first-#{$breakpoint} {
    order: -1;
  }

  .last-#{$breakpoint} {
    order: 1;
  }

  .hide-#{$breakpoint} {
    display: none;
  }

  .show-#{$breakpoint} {
    display: block;
  }

  .show-ib-#{$breakpoint} {
    display: inline-block;
  }

  %flex-global-styles-#{$breakpoint} {
    box-sizing: border-box;
    padding-left: #{$gutter/2};
    padding-right: #{$gutter/2};
  }
}

@each $breakpoint, $data in $breakpoints {
  $min-width: map-get($data, min-width);
  $gutter: map-get($data, gutter);

  @if ($min-width) {
    @media (min-width: $min-width) {
      @include flex-styles($breakpoint, $data, $gutter);
    }
  } @else {
    @include flex-styles($breakpoint, $data, $gutter);
  }
}

.nogrow {
  flex-basis: auto;
  flex-grow: 0;
}

.grow {
  flex-basis: 0;
  flex-grow: 1;
}

@for $i from 1 through 5 {
  .grow#{$i} {
    flex-basis: auto;
    flex-grow: #{$i};
  }
}
