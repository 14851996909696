.pagenotfound {
  &-main {
    background: url('../../img/404.jpg') no-repeat center;
    background-size: cover;
  }

  &-title {
    font-size: 2.4rem;

    @media (min-width: $md-width) {
      font-size: 3.5rem;
    }
  }

  &-content {
    color: $white-color;
    font-weight: $bold;
    height: 100vh;
    padding-top: 4rem;
    text-align: center;

    @media (min-width: $md-width) {
      font-size: 1.8rem;
      padding-top: 10rem;
      text-align: left;
    }

    .btn {
      width: 100%;

      @media (min-width: $md-width) {
        width: auto;
      }
    }
  }

  &-inner {
    @media (min-width: $md-width) {
      padding: 0;
    }
  }

  &-text {
    margin-bottom: 3rem;
  }

  &-or {
    margin: 3rem 2rem;
  }

  &-link {
    border-bottom: 1px solid $white-color;
    color: $white-color;
    display: inline-block;

    &:hover {
      border-color: palette(text, light);
      color: palette(text, light);
    }
  }

  .footer-main {
    margin-top: 0;
  }
}
