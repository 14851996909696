.tab {
  &-section {
    text-align: left;

    @media (min-width: $md-width) {
      margin: $gutter 0;
      padding: 2.5rem 0;
    }
  }

  &-header {
    background-color: palette(background);
    border-top: 1px solid palette(border);
    padding: 1.5rem 0;

    @media (min-width: $md-width) {
      background-color: transparent;
      border-top: 0;
      padding: 0;

      .small-ctn {
        width: 100%;
      }
    }
  }

  &-title {
    font-size: 1.6rem;
    font-weight: $bold;
    margin-bottom: 0;
    overflow: hidden;
    padding-right: 2.9rem;
    position: relative;
    text-align: left;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;

    @media (min-width: $md-width) {
      font-size: 3.5rem;
      margin-bottom: 20px;
      padding: 0;
      text-align: center;
      text-overflow: inherit;
      text-transform: inherit;
      white-space: inherit;
    }
    @media (max-width: $md-width + 1) {
      width: 100%;
    }

    .icon {
      @extend %vertical-center;
      font-size: 15px;
      right: 0;

      @media (min-width: $xs-width) {
        font-size: 16px;
      }
      @media (min-width: $sm-width) {
        font-size: 17px;
      }
      @media (min-width: $md-width) {
        display: none;
      }
    }
  }

  &-content {
    display: none;
    padding: 1.5rem;

    @media (min-width: $md-width) {
      padding: 0;
    }

    &:not(.hidden) {
      display: block;
    }

    &-section {
      @media (min-width: $md-width) {
        margin-bottom: $gutter;
      }
    }
  }
}
