//** steps order ***//
.order-steps {
  background-color: palette(background);
  border-bottom: 1px solid palette(border);
  left: 0;
  margin: auto;
  padding: 1rem .5rem;
  pointer-events: none;
  right: 0;
  text-align: center;
  top: $banner-size-stack;
  transition: .3s;
  z-index: 150;

  @media(min-width: $md-width) {
    background-color: transparent;
    border: 0;
    padding: 0 1.5rem;
    position: fixed;
    top: $banner-size-unstack;
  }

  .header-stack & {
    top: .5rem;
  }

  &-list {
    color: palette(text, light);
    font-size: 1.1rem;
    font-weight: $bold;
    margin: auto;
    max-width: 48.7rem;
    position: relative;
    text-align: center;

    @media(min-width: $md-width) {
      font-size: 1.6rem;
    }
  }

  &-item {
    opacity: .5;
    padding: 0 .5rem;
    pointer-events: all;
    position: relative;

    &.step-current {
      opacity: 1;

      .order-steps-number {
        border-color: palette(border, dark);

        &::before,
        &::after {
          opacity: .5;
        }
      }
    }

    @media (min-width: $md-width) {
      &.step-first {
        .order-steps-number {
          &::before {
            left: 100%;
            right: unset;
          }
        }
      }

      &.step-second {
        .order-steps-number {
          &::after {
            @include poa(50%, null, null, 100%);

            border-top: 1px solid palette(border, dark);
            content: '';
            width: 62.5px;
          }
        }
      }

      .order-steps-number {
        &::before {
          @include poa(50%, 100%, null, null);

          border-top: 1px solid palette(border, dark);
          content: '';
          width: 62.5px;
        }
      }
    }
  }

  &-inner {
    @media (min-width: $md-width) {
      display: block;
    }
  }

  @media (min-width: $md-width) {
    &-label {
      @include poa(100%, 0, null, 0);

      text-align: center;
    }
  }

  &-number {
    border: 1px solid palette(border, dark);
    border-radius: 50%;
    display: block;
    height: 2rem;
    line-height: 2rem;
    margin-right: 5px;
    position: relative;
    text-align: center;
    width: 2rem;

    @media(min-width: $md-width) {
      height: 4rem;
      line-height: 4rem;
      margin: 0 auto .5rem;
      width: 4rem;
    }
  }
}
