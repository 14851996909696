.password {
  form {
    padding: 15px 0;

    label {
      font-size: 13px;
      margin-bottom: 10px;
    }
  }

  .alert {
    margin-bottom: 2rem;
  }

  .box-footer {
    .box-footer-submit {
      margin-bottom: 30px;

      @media (min-width: $xs-width) {
        margin-bottom: 0;
      }
    }

    .btn {
      text-align: center;
      width: 100%;

      @media (min-width: $xs-width) {
        width: auto;
      }
    }
  }
}
