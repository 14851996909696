.h1 {
  @include heading(palette(text), 2rem, 1.5rem);

  text-align: center;

  @media (min-width: $md-width) {
    font-size: 3.5rem;
    margin-bottom: 2.5rem;
  }
}

.h2 {
  @include heading(palette(text), 2rem, 1.5rem);

  @media (min-width: $md-width) {
    font-size: 3.5rem;
    margin-bottom: 2.5rem;
  }
}

.h3 {
  @include heading(palette(text), 1.8rem, 1.3rem);

  @media (min-width: $md-width) {
    font-size: 2.4rem;
    margin-bottom: 1.5rem;
  }
}

.h4 {
  @include heading(palette(text), 1.6rem, 1rem, true, true);
}

.h5 {
  @include heading;
}

.title {
  display: block;
  font-weight: $bold;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

p {
  line-height: 1.4;
}

a {
  color: palette(text);
  text-decoration: none;
  transition: .3s;
}

hr {
  border: 0;
  border-top: 1px solid palette(border);
}

th,
td {
  padding: .5rem 1rem;
}

tbody {
  tr {
    &:nth-of-type(odd) {
      background-color: palette(background);
    }
  }
}
