html {
  color: palette(text);
  font-size: 62.5%;
  overflow-x: hidden;
  text-size-adjust: 100%;
}

body {
  font-family: $font-family;
  font-size: 1.6rem;
  line-height: 1.2;
  overflow-x: hidden;
}

.overtop {
  background: palette(primary);
  color: $white-color;
  height: $banner-size-unstack;
  transition: .3s;

  @media (min-width: $md-width) {
    height: $banner-size-unstack-md;
  }
}

.gototop {
  display: none;

  @media (min-width: $md-width) {
    @include position(fixed, null, 7rem, 7rem);

    background-color: $white-color;
    border: 1px solid palette(border);
    border-radius: 50%;
    cursor: pointer;
    display: block;
    height: 5rem;
    opacity: 0;
    text-align: center;
    transition: .3s;
    width: 5rem;

    &:hover {
      border-color: palette(border, dark);

      .icon {
        color: palette(text);
      }
    }

    .icon {
      @include poa(50%, 0, null, 0);

      color: palette(text, light);
      font-size: 1.1rem;
      text-align: center;
      transform: translateY(-50%);
      transition: .3s;
    }

    &.active {
      opacity: 1;
    }
  }
}

// Site content //
.site-content {
  //padding-top: $header-size-unstack + $banner-size-unstack;
  transition: .3s;

  @media (min-width: $md-width) {
    padding-top: $header-size-unstack-md + $banner-size-unstack-md + 40px;
  }

  @media (min-width: $lg-width) {
    padding-top: $header-size-unstack-lg + $banner-size-unstack-lg + 40px;
  }
}
