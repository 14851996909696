.order-detail {
  .help-block {
    margin: 0 auto;
  }

  .order-state {
    margin-bottom: .5rem;
    margin-left: .5rem;
  }

  .content-account {
    .account-title {
      padding: 0;

      &-order {
        @media (max-width: $md-width) {
          padding: 0 15px;
        }
      }
    }
  }

  .carrier_delay {
    color: palette(success);
    font-weight: $bold;
    margin-bottom: 10px;
  }

  .order-unique-ref {
    margin-bottom: 20px;
  }

  .order-follow {
    &-title {
      margin-bottom: 0;
    }
  }

  .order-follow-btns {
    @media (max-width: $sm-width) {
      margin-top: 30px;
    }

    .btn {
      margin-bottom: 10px;
      padding: 15px 30px;
      text-align: center;
      width: 100%;

      &.btn-small {
        border-color: palette(text);
        color: palette(text);
      }

      &:hover {
        color: $white-color;
      }
    }
  }

  .order-follow-price {
    background: palette(background);
    border: 1px solid palette(border);
    margin: 0 -1.5rem;

    @media (min-width: $md-width) {
      border-radius: $border-radius;
      margin: 0;
    }

    &-row {
      border-bottom: 1px solid palette(border);
      margin: 0;
      padding: 1.5rem 0;

      @media (min-width: $md-width) {
        padding: 15px 25px;
      }

      &:last-child {
        border: 0;
      }

      .total-price {
        font-size: 3rem;

        @media (max-width: $sm-width) {
          font-size: 2.5rem;
        }

        .current-price-tax-excl {
          color: palette(text, light);
          font-size: 1.8rem;
          font-weight: $bold;
          line-height: 1.8rem;
        }
      }

      .total-price-label {
        margin: 8px 0 10px;
        @media (max-width: $sm-width) {
          margin: 5px 0 7px;
        }
      }

      .total-price-ht-label {
        color: palette(text, light);
      }
    }
  }

  .order-follow-loyalty {
    display: block;
    margin-top: 15px;

    @media (max-width: $md-width) {
      margin: 15px 15px 0;
      width: auto;
    }

    .icon {
      margin-left: 10px;
    }
  }

  .return-text {
    margin-bottom: 20px;
    position: relative;
    transition: .3s;

    @media (max-width: $xs-width) {
      max-height: 160px;
      overflow: hidden;
    }
    @media (max-width: $md-width) {
      padding: 0 15px;
    }

    &.open {
      max-height: 100%;
      overflow: visible;
    }

    .text-more-link {
      background: linear-gradient(to bottom, palette(background, transparency) 0%, $white-color 40%, $white-color 100%);
      //background: linear-gradient(palette(background, transparency) 5%, $white-color 95%);
      border: 0;
      bottom: 0;
      color: palette(text, light);
      cursor: pointer;
      display: none;
      font-size: 14px;
      left: 0;
      padding: 40px 0 20px;
      position: absolute;
      text-align: center;
      text-transform: uppercase;
      width: 100%;
      z-index: 2;

      @media (max-width: $xs-width) {
        display: block;
      }

      .icon {
        margin-left: 10px;
      }
    }
  }

  .product-row {
    padding: 1.5rem 0;

    @media (min-width: $md-width) {
      margin: 0 0 1.5rem;
      padding: 1.5rem;
    }

    .product-name {
      cursor: pointer;
      font-weight: 300;

      strong {
        font-weight: $bold;
      }
    }

    .order_qte_input_ctn {
      display: none;

      .product-qty-ctn {
        float: none;
        margin: 0;
        width: 100%;

        @media (max-width: $xs-width) {
          margin-top: 10px;
        }

        .product-qty-input {
          width: 50px;
        }
      }
    }

    .product_quantity {
      left: -1rem;
      padding: 2rem;
      top: -1rem;
    }
  }

  .returns {
    .account-title {
      margin-bottom: 5px;
    }

    textarea {
      min-height: 75px;
    }
  }

  .order-follow-delivery {
    .account-title {
      margin-bottom: 20px;

      @media (max-width: $md-width) {
        border-bottom: 1px solid palette(border);
        margin: 0 -15px 20px;
        padding: 0 15px 10px;
      }
    }

    .addresses-row {
      margin-top: 15px;
    }
  }

  .order-follow-messages {
    padding-top: 50px;

    @media (max-width: $md-width) {
      padding: 50px 15px 0;
    }

    .account-title {
      margin-bottom: 20px;
    }

    .message {
      border: 1px solid palette(border);
      margin-bottom: 20px;
      padding: 1.5rem 2.5rem;

      &-name {
        font-weight: $bold;
        line-height: 14px;
      }

      &-date {
        line-height: 14px;
      }

      &-content {
        margin-top: 10px;
      }
    }
  }

  .order-follow-contact {
    .account-title {
      margin-bottom: 20px;
    }

    textarea {
      min-height: 140px;
    }
  }
}
