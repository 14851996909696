.compare-shipping {
  margin-top: 1.5rem;

  > .row {
    margin: 0 -7.5px;
  }

  .form-group {
    padding: 0 7.5px;

    label {
      left: 2.5rem;
    }
  }

  .price {
    min-width: 80px;
  }
}

.update-estimate-btn {
  cursor: pointer;
  font-size: 1.4rem;
}

.compare-datas-loading {
  margin-left: 5px;
}

.estimate-shipping {
  margin-bottom: 1rem;
}

.carriers-item {
  background-color: $white-color;
  border: 1px solid palette(border);
  border-radius: $border-radius;
  margin-bottom: 1rem;
  padding: 1rem;

  &.active {
    border-color: palette(border, dark);
  }

  .carriers-infos,
  .label-success {
    font-size: 1.4rem;
    margin-left: 3rem;
  }
}
