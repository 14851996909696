// Filtres actifs //
.enabled-filters {
  display: flex;
  min-height: 1px;

  @media (min-width: $md-width) {
    position: relative;
  }

  .filters-list {
    width: 100%;
  }

  &.has-filters {
    padding: 1.5rem;

    .title {
      text-transform: inherit;
    }
  }

  .title {
    display: inline-block;
    margin-bottom: 1rem;
    margin-right: 10px;

    @media (min-width: $md-width) {
      margin-bottom: 0.5rem;
    }
  }

  .filters-item {
    &:not(.clear-filters) {
      border: 1px solid palette(border, dark);
      border-radius: 3em;
      color: palette(text, light);
      display: inline-block;
      font-weight: $bold;
      margin-bottom: 1rem;
      margin-right: 10px;
      padding: 5px 12px;

      .filters-cancel {
        display: inline-block;
        margin-left: 10px;
        position: relative;
        width: 10px;
      }

      .icon {
        @extend %full-center;
        color: palette(text, light);
        font-size: 8px;
        padding: 1.1rem;
        transition: 0.3s;

        &:hover {
          color: palette(text);
        }
      }
    }
  }

  .clear-filters {
    border-bottom: 1px solid palette(text);
    line-height: 1;
    transition: 0.3s;

    &:hover {
      border-bottom-color: transparent;
    }
  }

  &.has-filters {
    opacity: 1;
  }
}

.panel-filters {
  background-color: palette(background);
  padding-bottom: 6rem;

  .apply-filters {
    @include position(fixed, null, -100%, 0);
    padding: 0 1.5rem 1.5rem;
    transition: 0.3s ease;
    width: 100%;

    @media (min-width: $sm-width) {
      width: 520px;
    }
  }

  .see-results {
    padding: 10px 35px;

    @media (min-width: $xs-width) {
      padding: 10px 50px;
    }
    @media (min-width: $sm-width) {
      padding: 10px 60px;
    }
  }

  .filters-item {
    margin-bottom: 1rem;
  }

  &.open {
    .apply-filters {
      right: 0;
    }
  }

  .panel {
    &-title {
      text-transform: initial;
    }

    &-head {
      background-color: transparent;
    }
  }

  .filter {
    border-top: 1px solid palette(border);
    margin: 0;
    padding: 0 1.5rem;

    &.open {
      .filter-title {
        .icon {
          &::before {
            content: icon(accordion-open);
          }
        }
      }
    }

    &-title {
      font-weight: $bold;
      padding: 1.5rem 0;
      text-transform: uppercase;

      .icon {
        transition: 0.3s;
      }
    }

    &-values {
      &-item {
        margin-bottom: 2rem;

        label {
          width: 100%;
        }
      }
    }
  }
}

// Filters on aside //
.filter-layout {
  &-enabled-filters {
    display: block;

    @media (min-width: $md-width) {
      display: none;
    }
  }

  .filter-category {
    @media (min-width: $md-width) {
      display: none;
    }
  }
}

// displayFilter //
.filter {
  @media (min-width: $md-width) {
    margin-bottom: 5rem;
    margin-top: 5rem;
  }

  .inner {
    display: none;
    max-height: 200px;
    overflow-x: auto;
  }

  &-values {
    &-item {
      margin-bottom: 1rem;

      a {
        pointer-events: none;
      }

      .checked {
        label {
          font-weight: $bold;
        }
      }
    }
  }

  &.open {
    .inner {
      display: block;
    }
  }

  &.filter-color {
    li {
      border: 1px solid palette(border);
      border-radius: 50%;
      float: left;
      font-size: 0;
      height: 35px;
      margin: 4px;
      position: relative;
      width: 35px;

      input {
        cursor: pointer;
        height: 100%;
        left: 0;
        margin: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }

      &:hover,
      &.checked {
        border-color: palette(border);

        &::before {
          color: palette(text);
          content: icon(check);
          font-family: $icon-font;
          font-size: 17px;
          left: 8px;
          position: absolute;
          top: 8px;
        }
      }
    }
  }

  &.layered_price {
    .inner {
      max-width: 190px;
      padding-bottom: 1rem;
    }
  }
}

// Range slider //
.ui-slider {
  background: palette(border, dark);
  height: 2px;
  position: relative;
  text-align: left;

  .ui-slider-range {
    background: palette(text);
    border: 0;
    display: block;
    font-size: 7px;
    height: 2px;
    position: absolute;
    top: 0;
    transition: none;
    z-index: 1;
  }

  .ui-slider-range-min {
    left: 0;
  }

  .ui-slider-range-max {
    right: 0;
  }

  .ui-slider-handle {
    background: palette(text);
    cursor: pointer;
    height: 10px;
    line-height: 10px;
    position: absolute;
    text-align: center;
    text-decoration: none;
    top: -4px;
    transition: none;
    width: 10px;
    z-index: 2;

    &:last-child {
      margin-left: -10px;
    }
  }
}

.filter-range-ctn {
  display: block;
  margin-bottom: 1rem;
  text-align: center;
}
