.table-div {
  line-height: 20px;
  margin-bottom: 30px;
  overflow: hidden;

  @media (min-width: $md-width) {
    border: 1px solid palette(border);
  }

  .table-head {
    background: none;
    color: $white-color;
    display: block;
    font-weight: $bold;
    overflow: hidden;
    padding-bottom: 15px;
    padding-top: 15px;
    text-transform: uppercase;

    @media (min-width: $md-width) {
      background: palette(background);
      display: flex;
    }

    .table-head-cell {
      display: none;

      @media (min-width: $md-width) {
        display: inline-block;
      }
    }
  }

  .table-row {
    border: 1px solid palette(border);
    overflow: hidden;
    padding: 10px 0;
    position: relative;
    width: 100%;

    @media (min-width: $md-width) {
      border: 0;
      display: flex;
      padding: 0;
    }

    .table-cell {
      padding: 5px 10px;

      @media (min-width: $md-width) {
        display: inline-block;
        padding: 15px;
      }
    }

    ul {
      margin: 0;
    }
  }

  .title-mobile {
    display: block;

    @media (min-width: $md-width) {
      display: none;
    }
  }

  .label-mobile {
    color: palette(text);
    display: inline-block;
    font-weight: bold;

    @media (min-width: $md-width) {
      display: none;
    }
  }
}
