.order-cart {
  .page-heading {
    margin-top: 3rem;
  }
}

.order-cart,
.order-process-pages {
  &:not(.menu-open) {
    .header-top {
      @media (max-width: $md-width + 1) {
        border-bottom: 1px solid palette(border);
        height: 55px;
      }
    }
  }
}

.order-process-pages {
  .header-top {
    border-bottom: 1px solid palette(border);
  }

  .header-search,
  .header-top-right,
  .overtop,
  .advmenu,
  .menu-action {
    display: none;
  }

  .header-top-left {
    order: 1;

    @media (min-width: $sm-width) {
      flex-basis: 20%;
      max-width: 20%;
    }
  }

  .header-logo {
    flex-basis: 50%;
  }

  .header-cart {
    display: block;

    @media (min-width: $md-width) {
      display: none;
    }

    .total-products,
    .open-target {
      color: palette(text, light);
    }

    .total-products {
      font-size: 1.4rem;
      font-weight: $bold;
      margin-right: 1rem;
    }

    .open-target {
      font-size: 1rem;
      margin-left: 1.1rem;
    }
  }

  .site-content {
    @media (min-width: $md-width) {
      padding-top: $header-size-unstack-nomenu + 30;
    }

    @media (min-width: $lg-width) {
      padding-top: $header-size-unstack-nomenu + 45;
    }
  }

  .submit {
    margin-top: 1.5rem;
  }
}

.cart-selection {
  border-top: 1px solid palette(border);
  margin-top: 8rem;
  padding-bottom: 7rem;
  padding-top: 5rem;
}

.empty-cart-ctn {
  margin: 3rem auto;

  .alert {
    margin-bottom: 3rem;
  }
}

.block-bordered {
  @media (min-width: $md-width) {
    border: 1px solid palette(border);
    border-radius: $border-radius;
    padding: 2rem 2rem 6rem;
  }

  &-section {
    padding-bottom: 1.5rem;
    position: relative;

    & + & {
      padding-top: 2rem;

      &::before {
        @include poa(null, -2rem, 100%, -2rem);

        border-top: 1px solid palette(border);
        content: '';
        display: block;
      }
    }
  }
}

.adresses-section,
.payment-section {
  .block-bordered {
    @media (min-width: $md-width) {
      padding-bottom: 0;
    }
  }
}

.carrier-infos-btns {
  margin-top: 2rem;
}

.delivery-option {
  padding-bottom: 1.5rem;

  .radio-box {
    @media (min-width: $md-width) {
      flex-wrap: nowrap;
    }
  }

  &-name {
    display: block;
    margin-bottom: .5rem;
  }

  &.active {
    .custom-radio {
      &::after {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  &-price {
    color: palette(primary);
    font-size: 1.8rem;
    font-weight: $bold;
  }

  &-infos {
    display: none;
    padding-bottom: 1.5rem;
  }

  &-extra {
    .address-order {
      margin-bottom: 2rem;
      margin-left: 1.6rem;
      margin-top: 1.5rem;
    }
  }

  .carrier-infos-schedules {
    margin-left: 1.7rem;
  }

  .delivery-other-address {
    border-bottom: 2px solid palette(border, dark);
    display: inline-block;
    font-weight: $bold;
    line-height: 1;
    margin-top: 2rem;

    &:hover {
      border-color: palette(text, light);
      color: palette(text, light);
    }
  }

  &.active {
    border-color: palette(border);

    .delivery-option-infos {
      display: block;
    }
  }

  &-name {
    font-weight: $bold;
  }

  &-delay {
    display: block;
  }

  .price-ctn {
    margin-bottom: 1.5rem;
    padding-left: 4.5rem;

    @media (min-width: $md-width) {
      align-items: flex-end;
      margin-bottom: 0;
      min-width: 110px;
      padding-left: 1.5rem;
      padding-right: 0;
    }
  }
}

.panel-soco {
  @media (min-width: $md-width) {
    overflow: hidden;
  }

  .so_box {
    padding: 0 1.5rem;
  }
}

.panel-mr {
  .mr_box {
    padding: 0 1.5rem;
  }
}

.panel-cp {
  .cp_box {
    padding: 0 1.5rem;
  }
}

.panel-mr,
.panel-soco,
.panel-cp {
  &.open {
    @media (min-width: $md-width) {
      max-width: 100%;
      min-width: 1040px;
      overflow: hidden;
      width: 50%;

      .panel-head,
      .map-ctn {
        min-width: 520px;
        width: 50%;
      }

      .panel-head {
        @include poa(0, 0);
      }
    }
  }

  .show-map {
    background-color: palette(background);
    border-bottom: 1px solid palette(border);
    border-top: 1px solid palette(border);
    color: palette(text, light);
    font-weight: $bold;
    margin-top: 70px;
    padding: 1.5rem;
  }

  .panel-content {
    clear: both;
    position: relative;

    > .row {
      margin: 0;
    }
  }

  .panel-pickup-content {
    padding: 0;

    @media (min-width: $md-width) {
      height: 100vh;
      margin-top: 50px;
      overflow: auto;
      padding: 0 1.5rem;
    }
  }

  #map,
  #mr-map,
  #cp-map {
    padding-bottom: 50%;
    position: relative;
    top: 70px;
    width: 100%;

    @media (min-width: $md-width) {
      padding-bottom: 100vh;
      position: absolute;
      top: 0;
      width: 50%;
    }
  }

  .panel-carrier-results {
    position: relative;
    top: 70px;

    @media (min-width: $md-width) {
      top: 0;
    }
  }

  .search-form {
    @include poa(0, 1.5rem, null, 1.5rem);
    margin: 1.5rem 0;

    @media (min-width: $md-width) {
      left: auto;
      position: relative;
      right: auto;
    }
    
    .form-control {
      border: 1px solid palette(border);
      border-radius: $border-radius;
    }

    input {
      background-color: $white-color;
      border: 0;
      border-radius: 2em;
      padding: 10px 40px;
      width: 100%;

      @media (min-width: $md-width) {
        padding: 15px 40px;
      }

      &:focus {
        outline: 0;
      }
    }
  }

  .search-submit {
    background-color: $white-color;
    opacity: 1;
    width: 50px;
  }

  .choose-recipient {
    margin-top: 1.5rem;

    .form-group {
      margin-bottom: 1rem;
    }
  }
}

.pickup-point {
  &-name {
    font-weight: $bold;
  }

  &-address,
  &-complement,
  &-hours-link {
    margin-left: 3rem;
  }

  &-hours-link {
    display: none;
    font-size: 1.4rem;
    margin-top: .5rem;

    @media (min-width: $md-width) {
      display: block;
    }

    .icon {
      margin-right: 5px;
    }
  }

  &-complement {
    margin-bottom: 3rem;
    margin-top: 1.5rem;

    @media (min-width: $md-width) {
      display: none;
    }
  }

  &-choose {
    margin-top: 2rem;
  }
}

.panel-pickup-no-point {
  width: 100%; 
}

.pickup-point-template {
  &.line {
    padding-left: 0;
    padding-right: 0;

    @media (min-width: $md-width) {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }
}

.panel-addresses {
  margin-top: 1.5rem;
  padding: 0 6rem;

  .panel-address-ctn {
    margin-bottom: 3rem;
    position: relative;

    &::after {
      border-bottom: 1px solid palette(border);
      content: '';
      display: block;
      margin: 0 -6rem;
      padding-top: 3rem;
    }
  }

  .panel-address {
    margin-left: 3rem;
  }

  .custom-radio {
    @include poa(0, null, null, 0);
  }

  .active {
    .choose-address {
      display: block;
    }
  }

  .choose-address {
    display: none;
    margin-left: -3rem;
    margin-top: 2rem;

    @media (min-width: $md-width) {
      margin-left: 0;
    }
  }
}

.panel-address-add-ctn {
  padding: 0 6rem;
  position: relative;

  .panel-address-add {
    display: none;
  }

  .title {
    color: palette(text, light);
  }

  &.open {
    .open-target {
      display: none;
    }

    .panel-address-add {
      display: block;
    }
  }
}

.order-recap-address,
.block-recap {
  .label {
    font-weight: $bold;

    &.alias {
      margin-bottom: 1rem;
    }
  }
}

.payment-module {
  &-validation {
    display: none;
    padding-bottom: 1.5rem;
  }

  .label {
    font-weight: $bold;
    margin-bottom: .5rem;
  }

  &-infos {
    margin-left: 3rem;
  }

  a,
  &.active {
    .payment-module-validation {
      display: block;
    }
  }

  .cgv {
    margin: 1.5rem 0;

    input {
      left: -1rem;
      padding: 2rem;
      top: -1rem;
    }
  }

  &-etransaction {
    .alert {
      margin-bottom: 1rem;
    }
  }

  .cb-list {
    margin-top: 1.5rem;
  }

  .cb-item {
    border: 1px solid palette(border);
    border-radius: $border-radius;
    margin-bottom: 1rem;
    padding: 1.5rem;
    transition: .3s;

    img {
      margin-right: 1rem;
    }

    &:hover {
      border-color: palette(border, dark);
    }

    &.active {
      background-color: palette(success, background);
      border-color: palette(success);
    }
  }
}

.order-confirmation {
  .page-heading {
    margin: 3rem 0 1.5rem;
  }

  .order-reference {
    display: block;
    margin-bottom: 1rem;
  }

  .alert {
    margin-bottom: 1rem;
  }

  .block-resume {
    margin-top: 1.5rem;
  }

  .cart {
    margin-top: 6rem;
  }

  .product-row {
    @media (min-width: $md-width) {
      margin-left: 0;
      margin-right: 0;
      padding-right: 11rem;
    }
  }

  .shipping-recap {
    margin-top: 3rem;

    @media (min-width: $md-width) {
      margin-top: 0;
    }
  }

  .delivery-option {
    &::before {
      border-top: 1px solid palette(border);
      content: '';
      display: block;
      margin: 1.5rem -1.5rem;

      @media (min-width: $md-width) {
        content: none;
      }
    }

    &-delay,
    &-date {
      font-size: 1.4rem;

      @media (min-width: $md-width) {
        font-size: 1.6rem;
      }
    }

    &-date {
      margin-bottom: 1rem;
    }

    &-complement {
      width: 100%;
    }
  }

  .delivery-option-img {
    line-height: 0;
    margin-top: 1rem;
  }

  @media (min-width: $md-width) {
    .cart-summary-prices-first {
      .line {
        padding: 1.5rem 11rem 1.5rem 10.2rem;
      }
    }

    .help-block {
      margin: 2rem 1.5rem;
    }
  }

  .cart-summary-prices-first-inner {
    border-top: 0;
    margin-top: 0;

    @media (min-width: $md-width) {
      border-top: 1px solid palette(border);
      margin-top: 2rem;
    }
  }

  .block-recap {
    background-color: palette(background);
    border: 1px solid palette(border);
    border-radius: $border-radius;
    color: palette(text, light);
    margin-bottom: 1.5rem;
    padding: 1.5rem;
  }
}

.no-carrier {
  .link-content {
    margin-bottom: 3rem;
  }
}
