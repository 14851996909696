.subcategories {
  &-img {
    max-height: 100%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    transition: 1s;

    &-ctn {
      background-color: palette(background);
      border-radius: $border-radius;
      height: 100%;
      padding: .7rem 1rem;
      text-align: center;
      width: 8rem;

      @media (min-width: $md-width) {
        height: 14rem;
        margin-bottom: 1.7rem;
        padding: 1.7rem;
        width: 100%;

        &:hover {
          .subcategories-img {
            transform: translateY(-50%) scale(1.05);
          }
        }
      }
    }
  }

  &-title-ctn {
    align-items: center;
    background-color: palette(background);
    border-radius: $border-radius;
    height: 5rem;
    justify-content: space-between;
    margin-bottom: 1rem;

    @media (min-width: $md-width) {
      align-items: flex-start;
      background-color: transparent;
      height: auto;
      justify-content: normal;
      margin-bottom: 0;
    }

    .subcategories-title {
      margin-bottom: 0;

      @media (min-width: $md-width) {
        margin-bottom: .5rem;
      }
    }
  }

  &-item {
    margin-bottom: 2.5rem;

    @media (min-width: $md-width) {
      margin-bottom: 5rem;
    }

    &-inner {
      margin: 0 auto;
      max-width: 430px;

      @media (min-width: $md-width) {
        margin: 0;
        max-width: 100%;
      }

      > .subcategories-link {
        font-weight: $bold;
        text-transform: uppercase;
      }
    }
  }

  &-title,
  .see-all {
    margin: 0 1rem;

    @media (min-width: $md-width) {
      margin: 0 1.7rem;
    }
  }

  &-title {
    margin-bottom: 1rem;

    @media (min-width: $md-width) {
      margin-bottom: .5rem;
    }
  }
}
