.section-block {
  padding: 50px 0;

  .section-block-header {
    text-align: center;

    .section-block-header-title {
      margin-bottom: 0;
    }

    .section-block-header-subtitle {
      margin-bottom: 30px;
    }
  }
}

.block-std {
  background: palette(background);
  border: 1px solid palette(border);
  border-radius: 2px;
  color: palette(text, light);
  margin-bottom: 1.5rem;
  padding: 15px;
  position: relative;

  @media (min-width: $md-width) {
    margin-bottom: 3rem;
  }

  .block-std-title {
    color: palette(text, light);
    display: block;
    font-weight: $bold;

    &.alias {
      margin-bottom: 1rem;
    }
  }

  .block-std-content {
    line-height: 20px;
  }

  .block-std-footer {
    margin-top: 20px;
  }
}

.block {
  display: block;
  margin-bottom: 15px;
}
