// Switch Menu //
.menu-action {
  display: flex;
  margin-top: .5rem;

  .icon {
    color: palette(text, light);
    font-size: 20px;
  }

  .label {
    display: none;
  }
}

.menu-open {
  overflow: hidden;

  .advmenu,
  .header-top-right {
    left: 0;
  }

  .overtop,
  .header-top {
    position: relative;
    z-index: 105;
  }
}

.header-stack {
  .menu-action {
    opacity: 1;
  }
}

// displayMenu //
.advmenu {
  background-color: palette(background);
  border-bottom: 1px solid palette(border);
  border-top: 1px solid palette(border);
  bottom: 0;
  height: 100%;
  left: -100%;
  overflow: auto;
  padding: 0 0 3rem;
  position: fixed;
  top: 0;
  transition: .3s;
  width: 100%;
  z-index: 1000;

  @media (min-width: $md-width) {
    border-top: 0;
    display: flex;
    left: 0;
    overflow: visible;
    padding: 0;
    position: relative;
    text-align: center;
    z-index: 1;

    .header-mobile {
      display: none;
    }
  }

  .index & {
    border-bottom-color: transparent;
  }

  &:hover {
    .advmenu-link {
      color: palette(text, light);
    }
  }

  .footer-infos {
    display: none;
  }

  &-universe-link {
    @include position(fixed, null, 1.5rem, 1.5rem, 1.5rem);

    display: none;
    height: 4rem;
    margin: 0 auto;
    max-width: 100%;
    padding-bottom: 8px;

    @media (min-width: $xs-width) {
      width: 400px;
    }

    @media (min-width: $md-width) {
      display: inline-block;
      margin-top: 3rem;
      position: static;
      width: auto;
    }
  }

  &-content {
    background-color: palette(background);
    padding: 1.5rem 3rem 0;
    position: relative;
    text-align: left;
    top: 5rem;

    @media (min-width: $sm-width) {
      top: 0;
    }

    @media (min-width: $md-width) {
      margin: 0;
      padding: 0;
      position: static;
      text-align: center;
    }

    @media (min-width: $lg-width) {
      margin: 0 -1.5rem;
    }
  }

  &-root-link {
    color: palette(text, light);
    font-size: 2rem;
    font-weight: $bold;
    position: relative;
    z-index: 2;

    @media (min-width: $md-width) {
      color: palette(text);
      font-size: 1.4rem;
    }

    @media (min-width: $lg-width) {
      font-size: 1.6rem;
    }

    @media (min-width: $xl-width) {
      font-size: 1.7rem;
    }
  }

  &-root-item {
    width: 100%;

    @media (min-width: $md-width) {
      width: auto;
    }

    &-content {
      margin: 0 auto;
      max-width: 100%;
      padding: 0 1.5rem 2rem;
      position: relative;
      width: 400px;

      @media (min-width: $md-width) {
        &::after {
          @include poa(0, 0, 0, -30%);
          background: url("../../img/line-hover.svg") no-repeat center;
          background-size: 50px;
          content: "";
          opacity: 0;
          pointer-events: none;
          transition: .3s ease-in-out;
        }
      }

      @media (min-width: $md-width) {
        padding: 0 0 2rem;
        width: auto;
      }

      @media (min-width: $lg-width) {
        padding: 0 1rem 2rem;

        &::after {
          background-size: auto;
        }
      }

      @media (min-width: $xl-width) {
        padding: 0 1.2rem 2rem;
      }
    }

    &:hover {
      .advmenu-root-item-content {
        &::after {
          bottom: 6px;
          left: 0;
          opacity: .2;
        }
      }

      .advmenu-root-link {
        @media (min-width: $md-width) {
          color: palette(primary);
        }
      }

      .advsubmenu {
        opacity: 1;
        pointer-events: auto;
        transition: opacity .3s .4s, visibility .1s .1s;
        visibility: visible;
      }
    }

    &.open {
      .advsubmenu {
        left: 0;
        overflow: visible;
        transition: .3s;

        .advmenu-universe-link {
          display: inline-block;
        }
      }
    }
  }

  &-cta {
    margin-top: 3rem;

    @media (min-width: $md-width) {
      margin-top: 0;
    }

    li {
      margin-bottom: 1rem;
    }

    .btn {
      text-align: center;
      width: 100%;
    }
  }
}

.see-all {
  &-link {
    border-bottom: 1px solid palette(border, dark);
    color: palette(text, light);
    font-weight: $bold;

    &:hover {
      color: palette(text);
    }
  }
}

.advsubmenu {
  background: $white-color;
  bottom: 0;
  left: -100%;
  overflow: hidden;
  position: absolute;
  text-align: left;
  top: 0;
  width: 100%;
  z-index: 3;

  @media (min-width: $md-width) {
    @include poa(3.5rem, 0, 0, 0);

    opacity: 0;
    overflow: visible;
    padding-top: 3.5rem;
    pointer-events: none;
    text-align: left;
    transition: visibility .3s .3s, opacity .2s .1s;
    visibility: hidden;
    z-index: 1;
  }

  @media (min-width: $md-width) {
    &::before {
      background-color: palette(background);
      bottom: 0;
      content: "";
      height: 3.5rem;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
    }
  }

  &::after {
    background-color: palette(shadow, overlay);
    content: "";
    height: 100vw;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  &-inner {
    background-color: $white-color;

    @media (min-width: $md-width) {
      background-color: palette(background);
      max-height: 70vh;
      overflow: auto;
    }
  }

  &-row {
    margin: 0 auto;
    max-width: 100%;
    padding: 1.5rem 0 6rem;
    position: relative;
    width: 430px;

    @media (min-width: $sm-width) {
      padding: 1.5rem 1.5rem 6rem;
      width: 460px;
    }

    @media (min-width: $md-width) {
      margin: 0 (-$gutter / 2);
      max-width: none;
      padding: 0 0 $gutter;
      width: auto;
    }
  }

  &-item {
    &:hover {
      .advsubmenu-img-desk {
        transform: scale(1.05);
      }
    }
  }

  &-title {
    border-bottom: 1px solid transparent;
    transition: .3s;
  }

  &-link {
    background-color: palette(background);
    border-radius: $border-radius;
    font-weight: $bold;
    margin-bottom: 1rem;
    padding: 1.5rem;
    text-transform: uppercase;

    @media (min-width: $md-width) {
      padding: 0;
    }

    &.menu-img {
      @media (min-width: $md-width) {
        text-align: center;

        .advsubmenu-title {
          display: block;
          margin: 2rem auto 4rem;
        }
      }
    }
  }

  a.advsubmenu-link {
    &:hover {
      .advsubmenu-title {
        border-color: palette(border, dark);
      }
    }
  }

  &-img-desk {
    border-radius: $border-radius;
    transition: 1.3s;

    &-ctn {
      border-radius: $border-radius;
      line-height: 0;
      overflow: hidden;
    }
  }

  &-child {
    &-menu {
      padding: 0 ($gutter / 2) 4rem;

      @media (min-width: $md-width) {
        padding-left: 0;
        padding-right: 0;
      }
    }

    &-link {
      display: inline-block;
      margin-bottom: .9rem;
    }
  }

  .ctn {
    @media (max-width: $md-width) {
      padding: 0;
    }
  }

  .close-tab {
    background: palette(background);

    @media (min-width: $md-width) {
      display: none;
    }

    &-link {
      display: block;
      margin: 0 auto;
      max-width: 100%;
      padding: 1rem 1.5rem;
      width: 400px;

      @media (min-width: $md-width) {
        padding: 0;
        width: 100%;
      }
    }

    .icon {
      font-size: 1.1rem;
      margin-right: 1rem;
    }
  }
}
