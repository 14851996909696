.product-row {
  border-top: 1px solid palette(border);
  margin: 0 -1.5rem;
  padding: 1.5rem;
  position: relative;

  @media (min-width: $md-width) {
    border: 1px solid palette(border);
    border-radius: $border-radius;
    margin-bottom: 1.5rem;
    padding: 1.5rem 2.5rem 1.5rem 1.5rem;
  }

  &:last-of-type {
    border-bottom: 1px solid palette(border);
  }

  .product-delete {
    display: inline-block;
    font-size: 1.4rem;
    margin-top: 1.5rem;
    transition: .3s;

    &:hover {
      text-decoration: underline;
    }
  }

  .current-price {
    font-size: 1.8rem;
  }

  .product-total {
    margin-top: 65px;

    @media (min-width: $md-width) {
      margin-top: 0;
    }
  }

  .product-img {
    max-width: 65px;

    @media (min-width: $md-width) {
      max-width: 100%;
    }

    &-ctn {
      @include poa(0, 0);

      @media (min-width: $md-width) {
        padding-right: 0;
        position: static;
      }
    }
  }

  .product-qty {
    &-ctn {
      border: 1px solid palette(button);
      border-radius: 2em;
      display: flex;
      margin-top: 2rem;
      width: 164px;

      @media (min-width: $md-width) {
        float: right;
        margin-top: 0;
        width: 124px;
      }
    }

    &-btn {
      font-size: 1.8rem;
      padding-left: 1.8rem;
      padding-right: 1.8rem;
    }

    &-btn,
    &-input {
      font-weight: $bold;
      padding-bottom: .9rem;
      padding-top: .9rem;

      @media (min-width: $md-width) {
        padding-bottom: 1.5rem;
        padding-top: 1.5rem;
      }
    }

    &-input {
      width: 58px;

      @media (min-width: $md-width) {
        font-size: 1.8rem;
        width: auto;
      }
    }
  }

  .product-availability {
    margin-top: 1rem;
  }
}
