%horizontal-center {
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}

%vertical-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

%full-center {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

%link {
  border-bottom: 0;
  display: inline-block;
  position: relative;

  &::after {
    @include poa(null, 0, 2px, 0);
    border-bottom: 0.5px solid palette(text, light);
    content: "";
    height: 1px;
    opacity: 0;
    transition: 0.1s;
    width: 100%;
  }

  &:hover {
    &::after {
      opacity: 1;
    }
  }
}

%link-content {
  @extend %link;

  &::after {
    opacity: 1;
  }

  &:hover {
    &::after {
      opacity: 0;
    }
  }
}
