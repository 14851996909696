.category-universe {
  .advfeaturedcategories {
    &-all-link {
      margin-top: 1.5rem;

      @media (min-width: $md-width) {
        margin-top: 3rem;
      }
    }

    &-item {
      flex-basis: auto;
      margin: 0 auto 1.5rem;
      width: 100%;

      @media (min-width: $md-width) {
        flex-basis: 0;
        margin: 0;
        width: auto;
      }
    }

    &-link {
      display: block;
      margin: 0 auto;
      max-width: 400px;

      @media (min-width: $md-width) {
        margin: 0;
        max-width: 100%;
      }
    }

    &-img-ctn {
      height: 3rem;
      margin: 0;
      position: relative;
      text-align: center;
      width: 3rem;
      z-index: 1;

      @media (min-width: $md-width) {
        height: 10rem;
        margin: 0 auto 2rem;
        width: 10rem;
      }
    }
  }

  .long-desc-inner {
    padding: 4rem 0 2rem;

    @media (min-width: $md-width) {
      padding-top: 7rem;
    }
  }

  .subcategories-children-item {
    margin-bottom: 0.2rem;
  }

  > .mea-ctn {
    padding-bottom: 0;
  }

  .subcategories-list {
    padding-top: ($gutter / 2);

    @media (min-width: $md-width) {
      padding-top: 5rem;
    }
  }
}
