.contact {
  &-header {
    margin-bottom: 2rem;

    @media (min-width: $md-width) {
      margin-bottom: 3rem;
    }
  }

  .mention {
    margin-bottom: 1.5rem;
  }

  .form-group-textarea {
    .form-control {
      height: 150px;
    }
  }

  &-form {
    .custom-select {
      label {
        pointer-events: none;
      }
    }
  }

  .g-recaptcha {
    transform: scale(.96);
    transform-origin: left top;

    @media (min-width: $xs-width) {
      transform: none;
    }
  }
}
