.advwishlist {
  &-btn {
    color: palette(text, light);

    .icon-heart {
      color: palette(primary);
    }

    &:hover {
      .icon {
        color: palette(primary);
      }
    }
  }
}

.wishlist {
  > .block-std {
    padding-left: 0;
  }

  &-list {
    .box-title {
      margin-bottom: .5rem;
    }
  }

  .icon {
    transition: .3s;
  }

  .delete,
  .default-wishlist {
    margin-top: 1rem;

    @media (min-width: $xs-width) {
      margin-top: 0;
    }
  }

  .delete {
    &:hover {
      .icon {
        color: palette(primary);
      }
    }
  }

  .is-default {
    span {
      @media (min-width: $md-width) {
        margin-left: 1rem;
      }
    }
  }

  .default-wishlist {
    &:hover {
      .icon {
        color: palette(primary);
      }
    }
  }
}

.panel-wishlist {
  .panel-block {
    & + .panel-block {
      border-top: 1px solid palette(border);
    }
  }

  .wishlist {
    &-error {
      margin-bottom: 1rem;
    }

    &-create {
      padding-top: 5rem;
    }

    &-new-ctn {
      display: none;
    }

    &-ctn {
      padding-bottom: 7rem;
      padding-top: 3rem;

      @media (min-width: $sm-width) {
        padding-bottom: 12rem;
        padding-top: 5rem;
      }
    }
  }

  .title {
    text-align: center;
  }

  .old-price {
    margin-left: 1rem;
  }

  .separator {
    margin-top: 7rem;
    padding-top: 3rem;

    @media (min-width: $sm-width) {
      margin-top: 12rem;
      padding-top: 5rem;
    }
  }
}
