.account {
  &-section {
    padding-bottom: 6rem;

    .page-heading-inner {
      @media (min-width: $md-width) {
        padding-bottom: 6rem;
      }
    }

    th {
      font-weight: $bold;
    }

    tr {
      border-bottom: 1px solid palette(border);
    }

    td {
      padding: 1.5rem;
    }
  }

  &-separation {
    margin-top: 4.5rem;

    @media (min-width: $md-width) {
      margin-top: 7.5rem;
    }
  }

  &-title {
    line-height: 1.2;
    margin-bottom: 0;
  }
}

.content-account {
  .alert {
    text-align: center;
    width: 100%;
  }

  tr {
    &:nth-of-type(odd) {
      background-color: transparent;
    }
  }

  @media (min-width: $md-width) {
    .std-bordered-dark {
      .account-title {
        min-height: 66px;
      }
    }
  }
}

.bordered {
  border: 1px solid palette(border);
}

.myaccount_order_container {
  border-radius: $border-radius;
  margin-bottom: 3rem;

  .order-row {
    border-left: 0;
    border-right: 0;
    margin: 0;
  }

  .history_btn {
    margin: 2rem 0;
  }

  .account-title {
    margin-top: 1.5rem;
  }
}

.rgpd-action {
  .btn {
    margin-top: 1rem;
  }
}

.std-bordered {
  border-radius: $border-radius;
  display: block;
  margin-bottom: 30px;
  padding: 20px;
  text-align: center;
  transition: .3s;

  @media (min-width: $md-width) {
    min-height: 200px;
  }

  &:hover {
    background-color: palette(background);
  }

  &-title {
    padding: 5px 0;
  }

  &-content {
    .link {
      &::after {
        opacity: 1;
      }

      &:hover {
        color: palette(text, light);

        &::after {
          opacity: 0;
        }
      }
    }
  }

  .icon {
    color: palette(primary);
    font-size: 2.8rem;
  }
}

.std-bordered-dark {
  background-color: palette(background);
}

.order-slip-row,
.order-return-row,
.discount-row,
.loyalty-row {
  border: 1px solid palette(border);
  border-radius: $border-radius;
  margin-bottom: 1rem;
  padding: 1.5rem 0;

  .label {
    color: palette(text, light);
    font-weight: $bold;
  }
}

.order-return-row {
  cursor: pointer;
}

.order-return {
  .block-order-detail {
    padding: 6rem 1.5rem 1.5rem;
  }
}

.discount-ctn,
.order-return,
.order-slip-ctn,
.wishlist,
.wishlists-list {
  padding: 0 1.5rem;

  .bold {
    font-weight: $bold;
  }
}

.order-return,
.order-slip,
.wishlist {
  .block-std {
    margin-bottom: 15px;
  }
}

.block-order-detail {
  margin-top: -16px;
}

.return-order-message {
  margin-bottom: 4.5rem;

  @media (min-width: $md-width) {
    margin-bottom: 7.5rem;
  }
}

.order-return-content {
  .table-head {
    color: palette(text, light);
  }
}

.default-wishlist {
  &.is-default {
    a {
      color: palette(primary);
    }
  }
}

.wishlisturl {
  .btn {
    margin-top: 20px;
  }
}

.wishlist-del {
  align-self: flex-start;
  cursor: pointer;
}

.module-referralprogram-program {
  .referral_form {
    margin: 20px 0 0;

    tr {
      border: 0;
    }

    td {
      padding: .5rem;
    }
  }

  .mb10 {
    margin-bottom: 10px;
  }

  .content-account-referral {
    margin-bottom: 20px;

    table {
      margin-bottom: 20px;
    }
  }
}

.address {
  .mention {
    margin-bottom: 1.5rem;
  }
}

.module-loyalty-default {
  .pagination {
    margin: 0;
    padding: 1.5rem 0;
  }
}

.discount-infos-text {
  p {
    margin-bottom: 1rem;
  }
}
