.advmenu {
  .footer-links {
    background-color: palette(background);
    max-width: 100%;

    @media (min-width: $md-width) {
      display: none;
    }
  }

  .advblocklink {
    &-inner {
      margin: 0 auto;
      max-width: 100%;
      width: 370px;
    }
  }
}

.advblocklink {
  &-link {
    border-bottom: 1px solid transparent;

    @media (min-width: $md-width) {
      display: inline-block;

      &:hover {
        border-bottom: 1px solid palette(border, dark);
      }
    }
  }
}
