//** AUTHENTICATION PAGE **//
.authentication {
  &:not(.order-process-pages) {
    .auth {
      margin-top: 2rem;
    }

    .page-heading {
      margin-top: 0;
    }
  }

  .tab-content {
    padding: 1.5rem 0;

    @media (min-width: $md-width) {
      padding: 1.5rem;
    }
  }
}

.birthdate {
  > .row {
    margin: 0 -.5rem;

    @media (min-width: $sm-width) {
      margin: 0 -1.5rem;
    }
  }

  .custom-select {
    padding: 0 .5rem;

    > span {
      padding-left: 1rem;

      &::after {
        margin-left: .5rem;
        margin-right: 1rem;
      }

      @media (min-width: $sm-width) {
        padding-left: 1.5rem;

        &::after {
          margin-left: 1rem;
          margin-right: 1.5rem;
        }
      }
    }

    @media (min-width: $sm-width) {
      padding: 0 1.5rem;
    }
  }
}
