.popover {
  .popover-title {
    background: $white-color;
    border: 0;
    font-size: 18px;
    font-weight: bold;
  }

  .popover-content {
    background: palette(background);
    @include poa(calc(-100% + 15px), null, null, 50%);
    box-shadow: 0 3px 5px rgba(0, 0, 0, .3);
    color: palette(text);
    font-size: 13px;
    max-width: 200px;
    min-width: 100px;
    opacity: 0;
    padding: 10px;
    pointer-events: none;
    text-align: center;
    transform: translateX(-50%);
    transition: opacity .3s ease 0s;
    z-index: 1;

    .inner {
      position: relative;
      z-index: 3;
    }

    &::after {
      @include poa(1px, 1px, 1px, 1px);
      background: $white-color;
      content: "";
      z-index: 2;
    }

    &::before {
      @include poa(null, null, -8px, 50%);
      background: $white-color;
      border: 1px solid #dfdad9;
      content: "";
      height: 16px;
      margin: 0 0 0 -8px;
      position: absolute;
      transform: rotate(-45deg);
      width: 16px;
      z-index: 1;
    }
  }

  &:hover .popover-content {
    opacity: 1;
    z-index: 3;
  }
}
